import { DateInput } from '../report-inputs/date-input'
import { GiftCardTypeInput } from '../report-inputs/gift-card-type-input'

import { ReportSortInput } from '../report-inputs/report-sort-input'

export const GiftCardReportFields = () => {
  return (
    <>
      <ReportSortInput />
      <DateInput
        dateStartKey="giftCardDateStart"
        dateEndKey="giftCardDateEnd"
      />
      <GiftCardTypeInput />
    </>
  )
}
