import { DateInput } from '../report-inputs/date-input'
import { OmnifulfillmentStoresInput } from '../report-inputs/omnifulfillment-stores-input'

export const OmniFulfillmentReportFields = () => {
  return (
    <>
      <DateInput
        dateStartKey="omniFulfillmentDateStart"
        dateEndKey="omniFulfillmentDateEnd"
      />
      <OmnifulfillmentStoresInput />
    </>
  )
}
