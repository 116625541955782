import { useQuery } from '@apollo/client'
import { Container } from '@mui/material'
import { ClockIcon, StarIcon } from '@sitoo/mui-components'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  FavoriteReportsDocument,
  LatestReportJobsDocument,
  ReportJobState,
} from '../../../generated/graphql'
import { useFavoriteReports } from '../../report/favorites/use-favorite-reports'
import { EmptySection } from './empty-section'
import { ReportCard } from './report-card'
import { ReportCardGroup } from './report-card-group'

export const ReportsOverviewPage = () => {
  const { t } = useTranslation(['reports'])
  const { favorites } = useFavoriteReports()
  const { data: favoriteReportsData, loading: isLoadingFavorites } = useQuery(
    FavoriteReportsDocument,
    {
      variables: {
        reportIds: favorites,
      },
      fetchPolicy: 'cache-and-network',
    },
  )

  const {
    data: latestReportJobsData,
    loading: isLoadingLatest,
    startPolling,
    stopPolling,
  } = useQuery(LatestReportJobsDocument, {
    fetchPolicy: 'cache-and-network',
    variables: { num: 8 },
  })

  useEffect(() => {
    if (
      latestReportJobsData?.reportJobs.items?.some((job) =>
        [ReportJobState.InProgress, ReportJobState.Pending].includes(
          job.jobstate,
        ),
      )
    ) {
      startPolling(3000)
    } else {
      stopPolling()
    }
  }, [latestReportJobsData, startPolling, stopPolling])

  return (
    <Container maxWidth={false}>
      <ReportCardGroup title={t('reports:favorites.favorite_label')}>
        {!favoriteReportsData?.favoriteReports.items?.length && (
          <EmptySection
            icon={<StarIcon fontSize="large" />}
            description={t('reports:no_favorites')}
            isLoading={isLoadingFavorites}
          />
        )}

        {favoriteReportsData?.favoriteReports.items?.map((report) => (
          <ReportCard key={report.reportid} reportData={report} />
        ))}
      </ReportCardGroup>

      <ReportCardGroup title={t('reports:latest.latest_label')}>
        {!latestReportJobsData?.reportJobs.items?.length && (
          <EmptySection
            icon={<ClockIcon fontSize="large" />}
            description={t('reports:latest.latest_empty')}
            isLoading={isLoadingLatest}
          />
        )}

        {latestReportJobsData?.reportJobs.items?.map((reportJob) => (
          <ReportCard key={reportJob.jobid} reportData={reportJob} />
        ))}
      </ReportCardGroup>
    </Container>
  )
}
