import { ManufacturerInput } from '../report-inputs/manufacturer-input'
import { ProductGroupInput } from '../report-inputs/product-group-input'
import { ReportSortInput } from '../report-inputs/report-sort-input'
import { SingleDateInput } from '../report-inputs/single-date-input'
import { StoreManagerWarehouseFilteringInput } from '../report-inputs/store-manager-warehouse-filtering-input'
import { WarehouseInput } from '../report-inputs/warehouse-input'

export const StockReportFields = () => {
  return (
    <>
      <ReportSortInput />
      <SingleDateInput dateEndKey="stockDateEnd" />
      <WarehouseInput warehouseFilterKey="warehouseId" />
      <ManufacturerInput />
      <ProductGroupInput />
      <StoreManagerWarehouseFilteringInput />
    </>
  )
}
