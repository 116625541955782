import { Box, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ViewPanel } from '../../../../components/view-panel'
import { HeaderSection } from './header-section'
import { TabsWithPanels } from './tabs-with-panels'
import { useMe } from '../../../../hooks/me'
import { SystemReportsPanel } from './system-reports-panel'
import { useReactiveVar } from '@apollo/client'
import { selectedReportIdVar } from '..'

export const SelectionViewPanel = () => {
  const { t } = useTranslation('reports')
  const { isAdmin } = useMe()
  const selectedReportId = useReactiveVar(selectedReportIdVar)

  return (
    <ViewPanel open={true}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <HeaderSection />
        <Box
          sx={{
            backgroundColor: (theme) => theme.palette.background.paper,
            marginTop: 1,
            flexGrow: 1,
          }}
        >
          {isAdmin ? <TabsWithPanels /> : <SystemReportsPanel />}
        </Box>
        <Box
          sx={{
            position: 'sticky',
            bottom: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end',
            padding: 1,
            background: (theme) => theme.palette.background.paper,
            borderTop: (theme) => `1px solid ${theme.palette.divider}`,
          }}
        >
          <Button disabled={!selectedReportId} size="small">
            {t('create.use_as_template')}
          </Button>
        </Box>
      </Box>
    </ViewPanel>
  )
}
