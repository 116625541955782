import { ListItem } from '@mui/material'
import { Option, SelectInput } from '../../../../inputs/select-input'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { OrderPaymentState } from '../../../../generated/graphql'
import { useFormContext } from 'react-hook-form'
import { Form } from '..'

export const PaymentStateInput = () => {
  const { t } = useTranslation('reports')

  const { control } = useFormContext<Form>()

  const orderPaymentStateOptions: Option[] = useMemo(
    () => [
      { name: t('edit.include_all'), value: '' },
      {
        name: t('payment_state_keys.None'),
        value: OrderPaymentState.None,
      },
      {
        name: t('payment_state_keys.Pending'),
        value: OrderPaymentState.Pending,
      },
      {
        name: t('payment_state_keys.Reserved'),
        value: OrderPaymentState.Reserved,
      },
      {
        name: t('payment_state_keys.Successful'),
        value: OrderPaymentState.Successful,
      },
      {
        name: t('payment_state_keys.Cancelled'),
        value: OrderPaymentState.Cancelled,
      },
      {
        name: t('payment_state_keys.Failed'),
        value: OrderPaymentState.Failed,
      },
    ],
    [t],
  )

  return (
    <ListItem>
      <SelectInput
        control={control}
        label={t('edit.payment_state')}
        name="reportdefinition.filters.orderPaymentState"
        options={orderPaymentStateOptions}
        slotProps={{ select: { displayEmpty: true } }}
      />
    </ListItem>
  )
}
