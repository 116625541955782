import { DatePicker, DatePickerProps } from '@mui/x-date-pickers-pro'
import { PickerValidDate } from '@mui/x-date-pickers/models'
import { FieldValues, useController, UseControllerProps } from 'react-hook-form'
import { useLocalizedDate } from '../../hooks/localized-date'
import { useDayJs } from '../../hooks/day-js'

type Props<TValue extends PickerValidDate = PickerValidDate> = Omit<
  DatePickerProps<TValue>,
  'value' | 'onChange'
> & {
  label?: string
  helperText?: string
}

export const DatePickerInput = <
  TFieldValues extends FieldValues,
  TValue extends PickerValidDate = PickerValidDate,
>(
  props: Props<TValue> & UseControllerProps<TFieldValues>,
) => {
  const { name, control, ...datePickerProps } = props
  const { getDateFormat } = useLocalizedDate()
  const dateFormat = getDateFormat()
  const dayJs = useDayJs()

  const { field, formState } = useController({ name, control })
  const { isSubmitting } = formState

  return (
    <DatePicker
      {...field}
      {...datePickerProps}
      label={props.label}
      disabled={isSubmitting}
      slotProps={{
        textField: {
          helperText: props.helperText,
        },
        ...datePickerProps.slotProps,
      }}
      value={field.value ? (dayJs(field.value).tz() as TValue) : null}
      onChange={(newValue) => {
        if (newValue?.isValid()) {
          field.onChange(newValue.toJSON())
        } else if (newValue === null) {
          field.onChange(null)
        }
      }}
      format={dateFormat}
    />
  )
}
