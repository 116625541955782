import { CircularProgress, Grid2 as Grid, Typography } from '@mui/material'
import { ReactNode } from 'react'

type Props = {
  icon: ReactNode
  description: string
  isLoading?: boolean
}

export const EmptySection = (props: Props) => {
  return (
    <Grid
      container
      width="100%"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      bgcolor="background.paper"
      gap={1}
      sx={{
        p: 10,
        color: 'text.secondary',
      }}
    >
      {props.isLoading ? (
        <CircularProgress />
      ) : (
        <>
          {props.icon}
          <Typography variant="body02">{props.description}</Typography>
        </>
      )}
    </Grid>
  )
}
