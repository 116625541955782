import { ListItem } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Form } from '..'
import { ProductGroupsInput } from '../../../../inputs/product-group-input'

export const ProductGroupInput = () => {
  const { control } = useFormContext<Form>()
  const { t } = useTranslation('reports')

  return (
    <ListItem>
      <ProductGroupsInput
        name={`reportdefinition.filters.productVatId`}
        control={control}
        label={t('edit.product_group')}
        textFieldProps={{ placeholder: t('edit.include_all') }}
      />
    </ListItem>
  )
}
