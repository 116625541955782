import { FormHelperText, ListItem, ListItemText, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ChangeEvent } from 'react'
import { useFormContext } from 'react-hook-form'
import { GeneralSettingsFormContext } from '../general-settings-form'
import { TextFieldInput } from '../../../../inputs/text-field-input'
import {
  CurrencyRate,
  GetConfigVarsDocument,
} from '../../../../generated/graphql'
import { useQuery } from '@apollo/client'
import { formatCurrencyRates } from '../utils/format-currency-rates'
import { SettingsListSection } from '../components/settings-section'
import { TimeZonesInput } from '../../../../inputs/time-zones-input'
import { CurrencyInput } from '../../../../inputs/currency-input'

const validateCurrencyRateFormat = (value: string) => {
  if (!value) return true

  const lines = value.split('\n')
  const currencyRateRegex = /^[A-Z]{3}\s+\d+(.|,)?\d{0,8}$/

  return lines.every((line) => currencyRateRegex.test(line))
}

export const CurrencyAndTimezone = () => {
  const { t } = useTranslation(['shared', 'settings'])
  const { control, setValue } = useFormContext<GeneralSettingsFormContext>()
  const { data } = useQuery(GetConfigVarsDocument)
  const isCurrencyRatesEnabled = data?.configVars?.foreignCurrency

  const handleCurrencyRateChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value
    const parsedValue = inputValue
      ?.split('\n')
      .map((line) => {
        const [code, rate] = line.replace(',', '.').split(' ')
        if (!code || !rate) return null
        const parsedRate = Number(rate)
        return {
          code,
          rate: isNaN(parsedRate) ? rate : parsedRate.toFixed(8).toString(),
        }
      })
      .filter((line) => line !== null)

    setValue('currency_rates', parsedValue, { shouldDirty: true })
  }

  const isCurrencyRateArray = (value: unknown): value is CurrencyRate[] => {
    return (
      Array.isArray(value) &&
      value.every(
        (item) =>
          typeof item === 'object' &&
          item !== null &&
          'code' in item &&
          'rate' in item,
      )
    )
  }

  return (
    <SettingsListSection title={t('settings:currency_and_timezone')}>
      <ListItem>
        <ListItemText>
          <TimeZonesInput
            control={control}
            name="timezone"
            label={t('shared:time_zones.time_zone')}
            required
          />
        </ListItemText>
      </ListItem>
      <ListItem>
        <ListItemText>
          <CurrencyInput name="currencycode" control={control} required />
        </ListItemText>
      </ListItem>

      {isCurrencyRatesEnabled && (
        <ListItem>
          <ListItemText>
            <TextFieldInput
              multiline
              label={t('settings:general.currency_rates')}
              minRows={4}
              name="currency_rates_data"
              control={control}
              transform={{
                input: (value) => {
                  if (isCurrencyRateArray(value)) {
                    return formatCurrencyRates(value)
                  }

                  return typeof value === 'string' ? value : ''
                },
              }}
              rules={{
                validate: {
                  format: (value) => {
                    if (typeof value !== 'string') return false

                    return (
                      validateCurrencyRateFormat(value) ||
                      t('settings:error.invalid_currency_rates_format')
                    )
                  },
                },
              }}
              onChange={handleCurrencyRateChange}
              sx={{
                '.MuiInput-input': {
                  fontFamily: "'Roboto Mono', monospace",
                },
              }}
            />
            <FormHelperText component="span">
              <p>{t('settings:general.currency_rates_helper_text')}</p>

              <Stack>
                <span>{t('settings:general.currency_rates_example')}</span>
                <span>NOK 1.0352</span>
                <span>DKK 1.6004</span>
                <span>EUR 11.9289</span>
                <span>USD 11.1668</span>
              </Stack>
            </FormHelperText>
          </ListItemText>
        </ListItem>
      )}
    </SettingsListSection>
  )
}
