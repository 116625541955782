import { useQuery } from '@apollo/client'
import { Tab, Tabs } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ReportsDocument } from '../../../../generated/graphql'
import { useTabs } from '../../../../hooks/tab'
import { ReportsPanel } from './reports-panel'
import { SystemReportsPanel } from './system-reports-panel'

enum TabLabels {
  SystemReports,
  Reports,
}

export const TabsWithPanels = () => {
  const { t } = useTranslation('reports')
  const [activeTab, setActiveTab] = useTabs(TabLabels, TabLabels.SystemReports)
  const { data } = useQuery(ReportsDocument, {
    fetchPolicy: 'cache-and-network',
    variables: { num: 0 },
  })

  return (
    <>
      <Tabs
        value={activeTab}
        onChange={(_, value: number) => {
          setActiveTab(value)
        }}
        variant="fullWidth"
      >
        <Tab
          value={TabLabels.SystemReports}
          label={t('create.system_templates')}
        />
        <Tab
          value={TabLabels.Reports}
          label={t('create.your_reports', {
            count: data?.reports.totalcount ?? 0,
          })}
        />
      </Tabs>
      {activeTab === TabLabels.SystemReports && <SystemReportsPanel />}
      {activeTab === TabLabels.Reports && <ReportsPanel />}
    </>
  )
}
