import { useMemo } from 'react'
import { FieldPath, FieldValues, UseControllerProps } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ProductGroupType } from '../../generated/graphql'
import { SelectInput } from '../select-input'
import { SxProps } from '@mui/system'
type Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = UseControllerProps<TFieldValues, TName> & {
  displayEmpty?: boolean
  helperText?: string
  label?: string
  sx?: SxProps
}

export const ProductGroupTypeInput = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>(
  props: Props<TFieldValues, TName>,
) => {
  const { name, control, label, displayEmpty = false, helperText, sx } = props
  const { t } = useTranslation('filter')

  const productGroupTypes = useMemo(
    () => [
      ...(displayEmpty
        ? [
            {
              value: '',
              name: t('filter:product_group_type_input.include_all'),
            },
          ]
        : []),
      {
        value: ProductGroupType.Product,
        name: t(`filter:product_group_type_input.${ProductGroupType.Product}`),
      },
      {
        value: ProductGroupType.Service,
        name: t(`filter:product_group_type_input.${ProductGroupType.Service}`),
      },
      {
        value: ProductGroupType.GiftCard,
        name: t(`filter:product_group_type_input.${ProductGroupType.GiftCard}`),
      },
      {
        value: ProductGroupType.Deposit,
        name: t(`filter:product_group_type_input.${ProductGroupType.Deposit}`),
      },
    ],
    [displayEmpty, t],
  )

  return (
    <SelectInput
      name={name}
      control={control}
      options={productGroupTypes}
      label={label ?? t('filter:product_group_type_input.label')}
      sx={sx}
      helperText={helperText}
      slotProps={{ select: { displayEmpty } }}
    />
  )
}
