import { useMemo } from 'react'
import { FieldValues, FieldPath, UseControllerProps } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { AutocompleteInput } from '../autocomplete-input'
import { GetWarehousesDocument } from '../../generated/graphql'
import { useQuery } from '@apollo/client'
import { SxProps, TextFieldProps, Theme } from '@mui/material'

type Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = UseControllerProps<TFieldValues, TName> & {
  multiple?: boolean
  label?: string
  helperText?: string
  sx?: SxProps<Theme>
  textFieldProps?: Omit<TextFieldProps, 'name' | 'required' | 'label'>
  required?: boolean
}

export const WarehousesInput = <TFieldValues extends FieldValues>(
  props: Props<TFieldValues>,
) => {
  const { name, control, textFieldProps, ...autocompleteProps } = props
  const { t } = useTranslation('filter')

  const { data, loading } = useQuery(GetWarehousesDocument)

  const options = useMemo(
    () => data?.allWarehouses.map(({ id }) => id) || [],
    [data?.allWarehouses],
  )

  return (
    <AutocompleteInput
      name={name}
      control={control}
      {...autocompleteProps}
      textFieldProps={textFieldProps}
      options={options}
      isLoading={loading}
      autocompleteProps={{
        noOptionsText: t('filter:warehouse_input.no_options'),
        limitTags: 5,
        getOptionKey: (option) => option,
        getOptionLabel: (id) => {
          const option = data?.allWarehouses.find(
            (warehouse) => warehouse.id === id,
          )
          return option?.name || ''
        },
      }}
    />
  )
}
