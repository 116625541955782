import { ListItem } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Form } from '..'
import { AutocompleteInput } from '../../../../inputs/autocomplete-input'
import { ReportMeasureProperty } from '../../../../generated/graphql'

type Props = {
  measureKeys: ReportMeasureProperty[]
}
export const MeasuresInput = (props: Props) => {
  const { control } = useFormContext<Form>()
  const { t } = useTranslation('reports')
  return (
    <ListItem>
      <AutocompleteInput
        name="reportdefinition.measures"
        label={t('edit.measures')}
        control={control}
        multiple
        options={props.measureKeys}
        autocompleteProps={{
          getOptionLabel: (value) => t(`report_keys.${value}`),
        }}
        required
        textFieldProps={{
          helperText: t('edit.groups_measures_helper_text'),
        }}
      />
    </ListItem>
  )
}
