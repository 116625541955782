import { useQuery } from '@apollo/client'
import {
  CashRegisterFragment,
  GetManufacturerDocument,
  GetProductGroupDocument,
  ReportDefinitionFilter,
  WarehouseFragment,
} from '../../../generated/graphql'
import { useDayJs } from '../../../hooks/day-js'
import { useTranslation } from 'react-i18next'
import { useCallback, useMemo } from 'react'

export type UseFormattedFilterChips = (args: {
  allCashRegisters: CashRegisterFragment[]
  allWarehouses: WarehouseFragment[]
  filters: ReportDefinitionFilter | undefined
}) => {
  formattedFilters: [string, string][]
}

const removeEmptyFilters = (
  item: [string, string | null],
): item is [string, string] => item.at(1) !== null

export const useFormattedFilterChips: UseFormattedFilterChips = ({
  filters,
  allCashRegisters,
  allWarehouses,
}) => {
  const { t } = useTranslation(['reports'])
  const dayJs = useDayJs()

  const { data: manufacturerData } = useQuery(GetManufacturerDocument, {
    fetchPolicy: 'cache-first',
    variables: { id: filters?.productManufacturerId ?? 0 },
    skip: !filters?.productManufacturerId,
  })

  const { data: productGroupData } = useQuery(GetProductGroupDocument, {
    variables: { id: filters?.productVatId ?? 0 },
    fetchPolicy: 'cache-first',
    skip: !filters?.productVatId,
  })

  const formatDateLocalized = useCallback(
    (date: string | null | undefined) => {
      const parsedDate = dayJs(date)
      return parsedDate.isValid() ? parsedDate.format('L LT') : null
    },
    [dayJs],
  )

  const formatOrderPurchaseType = useCallback(
    (orderIsNegative: boolean | null | undefined) => {
      if (orderIsNegative === null) {
        return null
      }
      return orderIsNegative
        ? t('filter_chips.refund_only')
        : t('filter_chips.sales_only')
    },
    [t],
  )

  const formatOrderHasRegisterId = useCallback(
    (orderHasRegisterId: boolean | null | undefined) => {
      if (orderHasRegisterId === null) {
        return null
      }
      return orderHasRegisterId
        ? t('filter_chips.pos')
        : t('filter_chips.webshop')
    },
    [t],
  )

  const formatWarehouse = useCallback(
    (warehouseId: number | null | undefined) => {
      if (!warehouseId) {
        return null
      }
      const warehouse = allWarehouses.find(({ id }) => warehouseId === id)
      return `${warehouse?.name} [${warehouse?.currencycode}]`
    },
    [allWarehouses],
  )

  const formatOrderRegisterId = useCallback(
    (orderRegisterId: string | null | undefined) => {
      if (orderRegisterId === null) {
        return null
      }
      const cashRegister = allCashRegisters.find(
        ({ registerid }) => registerid === orderRegisterId,
      )
      return t('filter_chips.pos_list_item', {
        number: cashRegister?.registernumber ?? '',
        key: cashRegister?.registerkey ?? '',
      })
    },
    [allCashRegisters, t],
  )

  const formatFromTranslationKey = useCallback(
    (key: string | null | undefined, baseKey: string) => {
      if (!key) {
        return null
      }
      return t(`${baseKey}.${key}`)
    },
    [t],
  )

  const formatProductManufacturer = useCallback(() => {
    if (!manufacturerData?.manufacturer) {
      return null
    }
    return manufacturerData.manufacturer.name
  }, [manufacturerData])

  const formatProductGroup = useCallback(() => {
    if (!productGroupData?.productGroup) {
      return null
    }
    return `${productGroupData.productGroup.name} (${productGroupData.productGroup.value}%)`
  }, [productGroupData])

  const formatWarehouseCurrencyCode = useCallback(
    (warehouseCurrencyCode: string | null | undefined) => {
      if (!warehouseCurrencyCode) {
        return null
      }

      return t(`filter_chips.warehouse_currency_code`, {
        currency: warehouseCurrencyCode,
      })
    },
    [t],
  )

  const formattedFilters = useMemo(() => {
    if (!filters) {
      return []
    }

    const formattedValues: Partial<
      Record<keyof ReportDefinitionFilter, string | null>
    > = {
      orderSearchText: filters.orderSearchText ?? null,
      orderVoucherName: filters.orderVoucherName ?? null,
      orderVoucherCode: filters.orderVoucherCode ?? null,
      orderDateStart: formatDateLocalized(filters.orderDateStart),
      orderDateEnd: formatDateLocalized(filters.orderDateEnd),
      orderIsNegative: formatOrderPurchaseType(filters.orderIsNegative),
      orderWarehouseId: formatWarehouse(filters.orderWarehouseId),
      orderRegisterId: formatOrderRegisterId(filters.orderRegisterId),
      warehouseDateStart: formatDateLocalized(filters.warehouseDateStart),
      warehouseDateEnd: formatDateLocalized(filters.warehouseDateEnd),
      warehouseId: formatWarehouse(filters.warehouseId?.at(0)),
      orderState: formatFromTranslationKey(
        filters.orderState?.join('') ?? null,
        'order_state_keys',
      ),
      orderHasRegisterId: formatOrderHasRegisterId(filters.orderHasRegisterId),
      orderPaymentState: formatFromTranslationKey(
        filters.orderPaymentState,
        'payment_state_keys',
      ),
      productProductGroupType: formatFromTranslationKey(
        filters.productProductGroupType,
        'product_group_type_keys',
      ),
      warehouseTransactionType: formatFromTranslationKey(
        filters.warehouseTransactionType,
        'warehouse_transaction_type_keys',
      ),
      productManufacturerId: formatProductManufacturer(),
      productVatId: formatProductGroup(),
      stockDateEnd: formatDateLocalized(filters.stockDateEnd),
      warehouseCurrencyCode: formatWarehouseCurrencyCode(
        filters.warehouseCurrencyCode,
      ),
      warehouseSearchText: filters.warehouseSearchText ?? null,
    }

    const formattedKeysAndValues: [string, string | null][] = Object.entries(
      formattedValues,
    ).map(([key, value]) => [`${t(`filter_keys.${key}`)}`, value])

    return formattedKeysAndValues.filter(removeEmptyFilters)
  }, [
    filters,
    formatDateLocalized,
    formatOrderPurchaseType,
    formatWarehouse,
    formatOrderRegisterId,
    formatFromTranslationKey,
    formatOrderHasRegisterId,
    formatProductManufacturer,
    formatProductGroup,
    formatWarehouseCurrencyCode,
    t,
  ])

  return {
    formattedFilters,
  }
}
