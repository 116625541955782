import { useFormContext } from 'react-hook-form'
import { TextFieldInput } from '../../../../inputs/text-field-input'
import { Form } from '..'
import { useTranslation } from 'react-i18next'
import { ListItem } from '@mui/material'

export const SalesTaxRegionInput = () => {
  const { control } = useFormContext<Form>()
  const { t } = useTranslation('reports')

  return (
    <ListItem>
      <TextFieldInput
        label={t('edit.sales_tax_group_region')}
        name="reportdefinition.filters.salesTaxGroupRegion"
        control={control}
      />
    </ListItem>
  )
}
