import { useQuery, useReactiveVar } from '@apollo/client'
import { Box, Button, List } from '@mui/material'
import { SearchIcon, SectionHeader } from '@sitoo/mui-components'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { ReportsDocument } from '../../../../generated/graphql'
import { ReportListItem } from './report-list-item'
import { enqueueSnackbar } from 'notistack'
import { selectedReportIdVar } from '..'
import { useFormFilter } from '../../../../hooks/form-filter'
import { TextFieldInput } from '../../../../inputs/text-field-input'

const PAGE_SIZE = 100

type Form = {
  searchText: string
}

export const ReportsPanel = () => {
  const { t } = useTranslation(['reports', 'shared'])
  const selectedReportId = useReactiveVar(selectedReportIdVar)

  const { queryParams, formContext } = useFormFilter<Form>({
    persistState: false,
    formProps: {
      defaultValues: {
        searchText: '',
      },
    },
  })

  const { searchText } = queryParams

  const { data, fetchMore } = useQuery(ReportsDocument, {
    fetchPolicy: 'cache-and-network',
    variables: {
      searchtext: searchText,
      num: PAGE_SIZE,
    },
  })

  const nextPageStart = (data?.reports?.start ?? 0) + PAGE_SIZE
  const totalCount = data?.reports?.totalcount ?? 0
  const hasMoreItems = nextPageStart <= totalCount

  const fetchMoreItems = useCallback(() => {
    fetchMore({
      variables: {
        start: nextPageStart,
        num: PAGE_SIZE,
      },
    }).catch(() => {
      enqueueSnackbar(t('shared:error.default'), { variant: 'error' })
    })
  }, [nextPageStart, fetchMore, t])

  return (
    <>
      <Box
        sx={{
          padding: 1,
          backgroundColor: (theme) => theme.palette.background.default,
        }}
      >
        <TextFieldInput
          name="searchText"
          control={formContext.control}
          placeholder={t('create.search_placeholder')}
          slotProps={{
            input: {
              startAdornment: <SearchIcon fontSize="medium" />,
              autoComplete: 'off',
            },
            htmlInput: {
              background: 'paper',
              type: 'search',
            },
          }}
        />
      </Box>

      {searchText && (
        <SectionHeader
          sx={{ backgroundColor: (theme) => theme.palette.background.page }}
        >
          {t('create.search_results_count', {
            count: totalCount,
          })}
        </SectionHeader>
      )}
      <List>
        {data?.reports?.items?.map((report) => (
          <ReportListItem
            onSelect={selectedReportIdVar}
            key={report.reportid}
            report={report}
            isSelected={selectedReportId === report.reportid}
          />
        ))}
      </List>
      {hasMoreItems && (
        <Box
          sx={{
            padding: 1,
            backgroundColor: (theme) => theme.palette.background.default,
          }}
        >
          <Button
            size="small"
            color="secondary"
            fullWidth
            onClick={fetchMoreItems}
          >
            {t('create.load_more')}
          </Button>
        </Box>
      )}
    </>
  )
}
