import { List } from '@mui/material'
import { SystemReportsDocument } from '../../../../generated/graphql'
import { ReportListItem } from './report-list-item'
import { useQuery, useReactiveVar } from '@apollo/client'
import { selectedReportIdVar } from '..'

export const SystemReportsPanel = () => {
  const { data } = useQuery(SystemReportsDocument, {
    fetchPolicy: 'cache-and-network',
  })
  const selectedReportId = useReactiveVar(selectedReportIdVar)

  return (
    <List>
      {data?.systemReports.map((report) => (
        <ReportListItem
          onSelect={selectedReportIdVar}
          key={report.reportid}
          report={report}
          isSelected={selectedReportId === report.reportid}
        />
      ))}
    </List>
  )
}
