import { ListItem } from '@mui/material'

import { useFormContext } from 'react-hook-form'
import { SelectInput, Option } from '../../../../inputs/select-input'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import {
  ReportDateMode,
  ReportDefinitionFilter,
} from '../../../../generated/graphql'
import { Form } from '..'
import { DatePickerInput } from '../../../../inputs/date-picker-input'
import {
  calculateDateSpan,
  ReportDateModeWithoutDefault,
} from '../../calculate-date-span'
import { useDayJs } from '../../../../hooks/day-js'
type Props = {
  dateStartKey: keyof Pick<
    ReportDefinitionFilter,
    | 'orderDateStart'
    | 'creditNoteDateStart'
    | 'giftCardDateStart'
    | 'omniFulfillmentDateStart'
    | 'warehouseDateStart'
    | 'salesTaxDateStart'
  >
  dateEndKey: keyof Pick<
    ReportDefinitionFilter,
    | 'orderDateEnd'
    | 'creditNoteDateEnd'
    | 'giftCardDateEnd'
    | 'omniFulfillmentDateEnd'
    | 'warehouseDateEnd'
    | 'stockDateEnd'
    | 'salesTaxDateEnd'
  >
}
export const DateInput = (props: Props) => {
  const { control, watch, setValue } = useFormContext<Form>()
  const { t } = useTranslation('reports')

  const dateMode = watch('datemode')
  const dayJs = useDayJs()

  const dateModeOptions: Option[] = useMemo(
    () =>
      Object.values(ReportDateMode).map((value) => ({
        name: t(`date_mode_keys.${value}`),
        value,
      })),
    [t],
  )

  const handleSetDates = (datemode: ReportDateModeWithoutDefault) => {
    const [startDate, endDate] = calculateDateSpan(datemode, dayJs())
    setValue(`reportdefinition.filters.${props.dateStartKey}`, startDate)
    setValue(`reportdefinition.filters.${props.dateEndKey}`, endDate)
  }
  return (
    <>
      <ListItem>
        <SelectInput
          control={control}
          label={t('edit.date_filter')}
          name="datemode"
          options={dateModeOptions}
          onChange={(e) =>
            handleSetDates(e.target.value as ReportDateModeWithoutDefault)
          }
        />
      </ListItem>
      {dateMode === ReportDateMode.Default && (
        <>
          <ListItem>
            <DatePickerInput
              control={control}
              label={t('edit.date_from')}
              name={`reportdefinition.filters.${props.dateStartKey}`}
            />
          </ListItem>
          <ListItem>
            <DatePickerInput
              control={control}
              label={t('edit.date_to')}
              name={`reportdefinition.filters.${props.dateEndKey}`}
            />
          </ListItem>
        </>
      )}
    </>
  )
}
