import { DateInput } from '../report-inputs/date-input'

import { ReportSortInput } from '../report-inputs/report-sort-input'

export const CreditNoteReportFields = () => {
  return (
    <>
      <ReportSortInput />
      <DateInput
        dateStartKey="creditNoteDateStart"
        dateEndKey="creditNoteDateEnd"
      />
    </>
  )
}
