import { ListItem } from '@mui/material'
import { TextFieldInput } from '../../../../inputs/text-field-input'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Form } from '..'
import { ReportDefinitionFilter } from '../../../../generated/graphql'

type Props = {
  searchTextFilterKey: keyof Pick<
    ReportDefinitionFilter,
    'orderSearchText' | 'warehouseSearchText'
  >
}

export const SearchTextInput = (props: Props) => {
  const { t } = useTranslation('reports')
  const { control } = useFormContext<Form>()
  return (
    <ListItem>
      <TextFieldInput
        control={control}
        label={t('edit.search_text')}
        name={`reportdefinition.filters.${props.searchTextFilterKey}`}
      />
    </ListItem>
  )
}
