import {
  CashRegisterFragment,
  ReportDefinitionFilter,
  WarehouseFragment,
} from '../../../generated/graphql'
import { useFormattedFilterChips } from './use-formatted-filter-chips'
import { Stack } from '@mui/system'
import { Chip } from '@mui/material'

type Props = {
  filters: ReportDefinitionFilter
  allCashRegisters?: CashRegisterFragment[]
  allWarehouses?: WarehouseFragment[]
}

export const FilterChips = ({
  filters,
  allCashRegisters = [],
  allWarehouses = [],
}: Props) => {
  const { formattedFilters = [] } = useFormattedFilterChips({
    filters,
    allCashRegisters,
    allWarehouses,
  })

  if (formattedFilters.length === 0) {
    return null
  }

  return (
    <Stack
      direction="row"
      gap={1}
      sx={{
        flexWrap: 'wrap',
        py: 1,
      }}
    >
      {formattedFilters.map(([key, value]) => (
        <Chip
          key={key}
          size="small"
          label={`${key}: ${value}`}
          color="grayLight"
        />
      ))}
    </Stack>
  )
}
