import { ListItem } from '@mui/material'
import { SelectInput, Option } from '../../../../inputs/select-input'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { Form } from '..'
import { WarehouseTransactionType } from '../../../../generated/graphql'

export const WarehouseTransactionTypeInput = () => {
  const { control } = useFormContext<Form>()
  const { t } = useTranslation('reports')

  const warehouseTransactionTypes = useMemo<Option[]>(
    () => [
      { name: t('edit.include_all'), value: '' },
      {
        name: t(
          `warehouse_transaction_type_keys.${WarehouseTransactionType.ManualIn}`,
        ),
        value: WarehouseTransactionType.ManualIn,
      },
      {
        name: t(
          `warehouse_transaction_type_keys.${WarehouseTransactionType.ManualOut}`,
        ),
        value: WarehouseTransactionType.ManualOut,
      },
      {
        name: t(
          `warehouse_transaction_type_keys.${WarehouseTransactionType.Refund}`,
        ),
        value: WarehouseTransactionType.Refund,
      },
      {
        name: t(
          `warehouse_transaction_type_keys.${WarehouseTransactionType.Sales}`,
        ),
        value: WarehouseTransactionType.Sales,
      },
      {
        name: t(
          `warehouse_transaction_type_keys.${WarehouseTransactionType.Stocktaking}`,
        ),
        value: WarehouseTransactionType.Stocktaking,
      },
    ],
    [t],
  )

  return (
    <ListItem>
      <SelectInput
        control={control}
        label={t('edit.transaction_type')}
        name="reportdefinition.filters.warehouseTransactionType"
        options={warehouseTransactionTypes}
        slotProps={{ select: { displayEmpty: true } }}
      />
    </ListItem>
  )
}
