import { useFormContext } from 'react-hook-form'
import { Form } from '..'
import { useTranslation } from 'react-i18next'
import { ListItem, ListItemText } from '@mui/material'
import { SwitchInput } from '../../../../inputs/switch-input'

export const StoreManagerWarehouseFilteringInput = () => {
  const { control } = useFormContext<Form>()
  const { t } = useTranslation('reports')
  return (
    <ListItem
      component="label"
      secondaryAction={
        <SwitchInput name="forcewarehousefilter" control={control} />
      }
    >
      <ListItemText primary={t('edit.store_manager_warehouse_filtering')} />
    </ListItem>
  )
}
