import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { GeneralSettingsFormContext } from '../general-settings-form'
import { SettingsListSection } from '../components/settings-section'
import { ListItem, ListItemText } from '@mui/material'
import { SelectInput } from '../../../../inputs/select-input'
import { useCallback } from 'react'
import { WarehousesInput } from '../../../../inputs/warehouses-input'

export const WarehouseSettings = () => {
  const { control } = useFormContext<GeneralSettingsFormContext>()
  const { t } = useTranslation(['shared', 'settings'])

  const getReservationOptions = useCallback(() => {
    const dayValues = [
      0, 1, 2, 3, 4, 5, 6, 7, 10, 14, 21, 30, 60, 90, 120, 180, 365,
    ]

    return dayValues.map((value) => ({
      name:
        value === 0
          ? t('settings:general.no_reservation')
          : t('shared:date.days', { count: value }),
      value,
    }))
  }, [t])

  return (
    <SettingsListSection title={t('settings:general.warehouse_settings_title')}>
      <ListItem>
        <ListItemText>
          <WarehousesInput
            name="warehouseid"
            control={control}
            label={t('settings:general.default_warehouse')}
            required
            textFieldProps={{
              helperText: t('settings:general.default_warehouse_helper_text'),
            }}
          />
        </ListItemText>
      </ListItem>
      <ListItem>
        <ListItemText>
          <SelectInput
            name="numdaysreserved"
            label={t('settings:general.reserve_products')}
            control={control}
            options={getReservationOptions()}
            helperText={t('settings:general.reserve_products_helper_text')}
          />
        </ListItemText>
      </ListItem>
    </SettingsListSection>
  )
}
