import { useQuery } from '@apollo/client'
import { Paper, Typography } from '@mui/material'
import { Box, Container } from '@mui/system'
import { ReportExtendedDocument } from '../../../../generated/graphql'
import { FilterChips } from '../../filter-chips'
import { GraphSectionPreview } from './graph-section-preview'
import { TableSectionPreview } from './table-section-preview'
import { useTranslation } from 'react-i18next'

type Props = {
  reportId: number
}

export const TemplatePreview = ({ reportId }: Props) => {
  const { t } = useTranslation('reports')
  const { data } = useQuery(ReportExtendedDocument, {
    variables: {
      reportId,
    },
  })
  const report = data?.report

  if (!report) {
    return null
  }

  return (
    <Container>
      <Box
        component={Paper}
        sx={{
          p: 2,
        }}
      >
        <Typography variant="body02">{t('create.preview_header')}</Typography>
        <FilterChips
          filters={report.reportdefinition.filters}
          allWarehouses={data?.allWarehouses}
          allCashRegisters={data?.allCashRegisters}
        />
      </Box>
      <Box>
        <GraphSectionPreview report={report} />
        <TableSectionPreview report={report} />
      </Box>
    </Container>
  )
}
