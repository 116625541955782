import { Grid, IconButton, useMediaQuery, useTheme } from '@mui/material'
import { CloseIcon, MenuIcon, Link } from '@sitoo/mui-components'
import { Link as RouterLink } from 'react-router-dom'
import { sidebarOpenState } from '../../cache'
import { Logo } from '../logo'
import { RootRoute } from '../../pages'
import { NavbarUserMenu } from '../navbar-user-menu'
import { SiteSelector } from '../site-selector'
import { NavContainer } from './styled'
import { useAbsolutePath } from '../../hooks/absolute-path'

type Props = {
  hideSidebar?: boolean
}

export const Navbar = (props: Props) => {
  const { hideSidebar } = props
  const theme = useTheme()
  const generatePath = useAbsolutePath()
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('sm'))

  const toggleSidebar = () => {
    sidebarOpenState(!sidebarOpenState())
  }

  return (
    <NavContainer>
      <Grid
        container
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '100%',
        }}
        columns={12}
        gap={0}
      >
        <Grid
          item
          xs={2}
          sm="auto"
          sx={{
            width: (theme) => (hideSidebar ? theme.spacing(5) : 'auto'),
            [theme.breakpoints.up('sm')]: {
              display: 'none',
            },
          }}
        >
          {!hideSidebar && (
            <IconButton onClick={toggleSidebar}>
              {sidebarOpenState() ? (
                <CloseIcon fontSize="medium" />
              ) : (
                <MenuIcon fontSize="medium" />
              )}
            </IconButton>
          )}
        </Grid>

        <Grid
          item
          xs={8}
          sm="auto"
          sx={{
            minWidth: (theme) => theme.spacing(34),
            [theme.breakpoints.down('sm')]: {
              minWidth: 'auto',
              flexGrow: 1,
              display: 'grid',
              justifyContent: 'center',
            },
          }}
        >
          <Link
            component={RouterLink}
            to={generatePath(RootRoute.Home)}
            color="inherit"
            sx={{
              ':hover': { color: 'text.primary' },
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Logo
              sx={{
                maxHeight: 30,
                maxWidth: '100%',
              }}
            />
          </Link>
        </Grid>

        <Grid
          item
          xs
          sx={{ [theme.breakpoints.down('sm')]: { display: 'none' } }}
        />

        <Grid
          item
          sx={{
            height: '100%',
            [theme.breakpoints.down('sm')]: { display: 'none' },
          }}
        >
          {isDesktop && <SiteSelector sx={{ ml: 3, height: '100%' }} />}
        </Grid>

        <Grid
          item
          xs={2}
          sm="auto"
          sx={{
            [theme.breakpoints.down('sm')]: {
              display: 'flex',
              justifyContent: 'end',
            },
            [theme.breakpoints.up('sm')]: {
              marginLeft: (theme) => theme.spacing(3),
            },
          }}
        >
          <NavbarUserMenu />
        </Grid>
      </Grid>
    </NavContainer>
  )
}
