import { useMemo } from 'react'
import { Option } from '../../../../inputs/select-input'
import { useFormContext, useWatch } from 'react-hook-form'
import { Form } from '..'
import { useTranslation } from 'react-i18next'
import { AutocompleteInput } from '../../../../inputs/autocomplete-input'
import { ListItem } from '@mui/material'

export const ReportSortInput = () => {
  const { t } = useTranslation(['reports', 'shared'])
  const { control } = useFormContext<Form>()

  const [groups, measures] = useWatch({
    control,
    name: ['reportdefinition.groups', 'reportdefinition.measures'],
  })

  const stringify = (key: string, asc: boolean): string =>
    JSON.stringify([{ key, asc }])

  const sortOptions = useMemo<Option[]>(() => {
    const properties = [groups, measures]
      .flat()
      .filter((property) => typeof property === 'string')

    const options = properties.flatMap((property) => [
      {
        name: t(`edit.sort_asc`, { key: t(`report_keys.${property}`) }),
        value: stringify(property, true),
      },
      {
        name: t(`edit.sort_desc`, { key: t(`report_keys.${property}`) }),
        value: stringify(property, false),
      },
    ])

    return options
  }, [groups, measures, t])
  if (!measures && !groups) return null
  return (
    <ListItem>
      <AutocompleteInput
        control={control}
        label={t('edit.sort_order')}
        name="reportdefinition.sort"
        options={sortOptions.map((option) => option.value)}
        transform={{
          input: (value) => {
            // The API supports multiple sort options, but to keep it simple we
            // only support one.
            const [firstSortOption] = value

            if (firstSortOption?.key) {
              return stringify(firstSortOption.key, firstSortOption.asc)
            }
          },
          output: (value) => {
            if (typeof value === 'string') {
              return JSON.parse(value)
            }
          },
        }}
        autocompleteProps={{
          getOptionKey: (option) => String(option),
          getOptionLabel: (value) => {
            return (
              sortOptions.find((option) => option.value === value)?.name ?? ''
            )
          },
        }}
      />
    </ListItem>
  )
}
