import { Grid } from '@mui/system'
import { SelectionViewPanel } from './selection-view-panel'
import { TemplatePreview } from './template-preview'
import { makeVar, useReactiveVar } from '@apollo/client'
import { NoReportSelected } from './no-report-selected'

export const selectedReportIdVar = makeVar<number | undefined>(undefined)

export const ReportCreatePage = () => {
  const selectedReportId = useReactiveVar(selectedReportIdVar)

  return (
    <Grid container flexWrap="nowrap">
      <Grid size="grow" key={selectedReportId}>
        {selectedReportId ? (
          <TemplatePreview reportId={selectedReportId} />
        ) : (
          <NoReportSelected />
        )}
      </Grid>
      <Grid>
        <SelectionViewPanel />
      </Grid>
    </Grid>
  )
}
