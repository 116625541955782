/* eslint-disable @typescript-eslint/no-misused-promises */
import { useMutation, useQuery } from '@apollo/client'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid2 as Grid,
  IconButton,
  List,
  Paper,
} from '@mui/material'
import { CloseIcon, SectionHeader } from '@sitoo/mui-components'
import { enqueueSnackbar } from 'notistack'
import { useContext, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FilterViewPanelFooter } from '../../../components/filter-view-panel/filter-view-panel-footer'
import { ViewPanel } from '../../../components/view-panel'
import {
  Report,
  ReportDateMode,
  ReportDocument,
  ReportExtendedDocument,
  ReportGroupsAndMeasuresDocument,
  ReportOrderState,
  ReportQuery,
  ReportType,
  UpdateReportDocument,
} from '../../../generated/graphql'
import { useTracking } from '../../../hooks/tracking'
import { getErrorMessages } from '../../../utils/error-mapping'
import { stripNullValues } from '../../../utils/strip-null-values'
import { NullToStringRecursive } from '../../../utils/types'
import { GroupsInput } from './report-inputs/groups-input'
import { MeasuresInput } from './report-inputs/measures-input'
import { ReportNameInput } from './report-inputs/report-name-input'
import { CreditNoteReportFields } from './report-type-fields/credit-note-report-fields'
import { GiftCardReportFields } from './report-type-fields/gift-card-report-fields'
import { OmniFulfillmentReportFields } from './report-type-fields/omnifulfillment-report-fields'
import { OrderReportFields } from './report-type-fields/order-report-fields'
import { ProductReportFields } from './report-type-fields/product-report-fields'
import { SalesTaxReportFields } from './report-type-fields/sales-tax-report-fields'
import { StockReportFields } from './report-type-fields/stock-report-fields'
import { WarehouseReportFields } from './report-type-fields/warehouse-report-fields'
import { ReportsContext, ReportsContextType } from '../reports-context'
import { ReportDescriptionInput } from './report-inputs/report-description-input'

type Props = {
  onClose(): void
  isOpen: boolean
  reportId: number
}

export type Form = NullToStringRecursive<ReportQuery['report']>

export const EditReportViewPanel = ({ onClose, reportId, isOpen }: Props) => {
  const { t } = useTranslation(['reports', 'shared'])
  const { trackFormSuccess, trackFormError, trackButtonClick } = useTracking()

  const { report } = useContext(ReportsContext) as ReportsContextType

  const [updateReportMutation] = useMutation<Report>(UpdateReportDocument, {
    refetchQueries: [ReportDocument, ReportExtendedDocument],
  })

  const { data: reportGroupsAndMeasureData } = useQuery(
    ReportGroupsAndMeasuresDocument,
    {
      variables: {
        reportType: report?.reporttype as ReportType,
      },
      skip: !report?.reporttype,
    },
  )

  const formContext = useForm<Form>({
    defaultValues: {
      datemode: ReportDateMode.Default,
      reportname: '',
      reportdescription: '',
      reportdefinition: {
        sort: [],
        groups: [],
        measures: [],
        filters: {
          orderDateStart: '',
          orderDateEnd: '',
          orderPaymentState: '',
          orderSearchText: '',
          orderRegisterId: '',
          orderVoucherCode: '',
          orderVoucherName: '',
          orderHasRegisterId: '',
          orderIsNegative: '',
          stockDateEnd: '',
          warehouseDateStart: '',
          warehouseDateEnd: '',
          warehouseTransactionType: '',
          omniFulfillmentDateStart: '',
          omniFulfillmentDateEnd: '',
          omniFulfillmentStoreSales: '',
          omniFulfillmentStoreDelivery: '',
          creditNoteDateStart: '',
          creditNoteDateEnd: '',
          giftCardDateStart: '',
          giftCardDateEnd: '',
          giftCardType: '',
          productProductGroupType: '',
          salesTaxDateStart: '',
          salesTaxDateEnd: '',
          salesTaxIsRefund: true,
          salesTaxGroupRegion: '',
          orderState: [ReportOrderState.Open, ReportOrderState.Closed],
        },
      },
    },
  })

  const { handleSubmit, reset } = formContext

  useEffect(() => {
    if (!report) return

    reset(stripNullValues(report))
  }, [report, reset, t])

  const onSubmit = (formData: Form): void => {
    trackButtonClick({
      name: 'report-save',
      reportId,
    })
    const { reportid, ...rest } = formData

    updateReportMutation({
      variables: {
        reportId: reportid,
        report: stripNullValues(rest, {
          deleteEmptyStrings: true,
        }),
      },
    })
      .then(() => {
        enqueueSnackbar(t('status_messages.update_success'), {
          variant: 'success',
        })
        trackFormSuccess({
          name: 'report',
        })
      })
      .catch((error) => {
        const [firstErrorMessage] = getErrorMessages(error)
        enqueueSnackbar(firstErrorMessage, {
          variant: 'error',
        })
        trackFormError({
          name: 'report',
          errorMessage: firstErrorMessage,
        })
      })
  }

  const onInvalid = () => {
    enqueueSnackbar(t('status_messages.invalid_form'), {
      variant: 'error',
    })
  }

  const measureKeys =
    reportGroupsAndMeasureData?.reportGroupsAndMeasures.measures || []
  const groupKeys =
    reportGroupsAndMeasureData?.reportGroupsAndMeasures.groups || []
  if (!report?.reporttype) return null
  return (
    <ViewPanel open={isOpen}>
      <Paper
        elevation={0}
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: 10,
          padding: (theme) => theme.spacing(1, 1, 1, 2),
          borderBottom: (theme) => `1px solid ${theme.palette.gray10}`,
        }}
      >
        <Grid container>
          <Grid size="grow" sx={{ display: 'flex', alignItems: 'center' }}>
            <SectionHeader sx={{ padding: 0, backgroundColor: 'transparent' }}>
              {t('edit.report_configuration')}
            </SectionHeader>
          </Grid>
          <Grid>
            <IconButton onClick={onClose}>
              <CloseIcon fontSize="medium" />
            </IconButton>
          </Grid>
        </Grid>
      </Paper>

      <FormProvider {...formContext}>
        <form>
          <Accordion defaultExpanded className="MuiAccordionRoot">
            <AccordionDetails sx={{ px: 0, py: 1.5 }}>
              <List sx={{ '.MuiListItem-root': { mb: 0.5 } }}>
                <ReportNameInput />
                <ReportDescriptionInput />
                {groupKeys.length > 0 && <GroupsInput groupKeys={groupKeys} />}
                <MeasuresInput measureKeys={measureKeys} />
              </List>
            </AccordionDetails>
          </Accordion>

          <Accordion defaultExpanded className="MuiAccordionRoot">
            <AccordionSummary aria-controls="filter">
              <SectionHeader sx={{ p: 0 }}>
                {t('edit.sorting_filtering')}
              </SectionHeader>
            </AccordionSummary>
            <AccordionDetails sx={{ px: 0, pt: 1.5, pb: 0 }}>
              <List sx={{ '.MuiListItem-root': { mb: 0.5 } }}>
                {report.reporttype === ReportType.Order && (
                  <OrderReportFields />
                )}
                {report.reporttype === ReportType.Product && (
                  <ProductReportFields />
                )}
                {report.reporttype === ReportType.Creditnote && (
                  <CreditNoteReportFields />
                )}
                {report.reporttype === ReportType.GiftCard && (
                  <GiftCardReportFields />
                )}
                {report.reporttype === ReportType.SalesTax && (
                  <SalesTaxReportFields />
                )}
                {report.reporttype === ReportType.Stock && (
                  <StockReportFields />
                )}
                {report.reporttype === ReportType.Warehouse && (
                  <WarehouseReportFields />
                )}
                {report.reporttype === ReportType.OmniFulfillment && (
                  <OmniFulfillmentReportFields />
                )}
              </List>
            </AccordionDetails>
          </Accordion>

          <FilterViewPanelFooter>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center',
                gap: 2,
              }}
            >
              <Button size="small" onClick={handleSubmit(onSubmit, onInvalid)}>
                {t('shared:action.save')}
              </Button>
            </Box>
          </FilterViewPanelFooter>
        </form>
      </FormProvider>
    </ViewPanel>
  )
}
