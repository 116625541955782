import { useMutation, useQuery } from '@apollo/client'
import { SectionHeader } from '@sitoo/mui-components'
import { useEffect, useMemo, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { RootRoute } from '../..'
import { CountriesInput } from '../../../inputs/countries-input'
import { CurrencyInput } from '../../../inputs/currency-input'
import { SelectInput } from '../../../inputs/select-input'
import { SettingsDetailDialog } from '../../../components/settings-detail-dialog'
import { StoresInput } from '../../../inputs/stores-input'
import { SwitchInput } from '../../../inputs/switch-input'
import { TextFieldInput } from '../../../inputs/text-field-input'
import {
  AddUpdateSettingsWarehouseDocument,
  DeleteSettingsWarehouseDocument,
  GetStoresDocument,
  SettingsWarehouseDocument,
  SettingsWarehouseQuery,
  SettingsWarehousesDocument,
  WarehouseType,
  WarehouseUseType,
} from '../../../generated/graphql'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { useScrollToError } from '../../../hooks/scroll-to-error'

type Warehouse = SettingsWarehouseQuery['warehouse']

export const WarehouseDialog = () => {
  const { t } = useTranslation([
    'shared',
    'settings',
    'countries',
    'warehouses',
  ])

  const { id } = useParams()
  const warehouseId = Number(id)
  const isNewWarehouse = !warehouseId

  const navigate = useNavigate()
  const generatePath = useAbsolutePath()

  const { data, loading: fetchLoading } = useQuery(SettingsWarehouseDocument, {
    variables: { warehouseId: Number(warehouseId) },
    fetchPolicy: 'cache-and-network',
    skip: !warehouseId,
  })

  const { data: storesData, loading: fetchStoresLoading } =
    useQuery(GetStoresDocument)

  const [addUpdateWarehouseMutation, { loading: addLoading }] = useMutation(
    AddUpdateSettingsWarehouseDocument,
    { refetchQueries: [SettingsWarehousesDocument] },
  )

  const [deleteWarehouseMutation, { loading: deleteLoading }] = useMutation(
    DeleteSettingsWarehouseDocument,
    {
      refetchQueries: [SettingsWarehousesDocument],
    },
  )

  const isLoading =
    fetchLoading || fetchStoresLoading || addLoading || deleteLoading

  const warehouseType = useMemo(() => {
    return Object.values(WarehouseType).map((value) => ({
      value,
      name: t(`warehouses:warehouse_type.${value}`),
    }))
  }, [t])

  const warehouseUseType = useMemo(() => {
    return Object.values(WarehouseUseType).map((value) => ({
      value,
      name: t(`warehouses:warehouse_use_type.${value}`),
    }))
  }, [t])

  const { reset, control, handleSubmit, getValues, formState } =
    useForm<Warehouse>({
      defaultValues: {
        warehousetype: WarehouseType.FIFO,
        usetype: WarehouseUseType.FRONT,
      },
    })

  const deleteItem = async () => {
    if (warehouseId) {
      await deleteWarehouseMutation({ variables: { warehouseId: warehouseId } })
    }
  }

  const onClose = () => {
    void navigate(generatePath(RootRoute.SettingsWarehouses))
  }

  const nameRef = useRef<HTMLDivElement>(null)
  const countryRef = useRef<HTMLDivElement>(null)
  const currencyRef = useRef<HTMLDivElement>(null)

  useScrollToError({ ref: nameRef, name: 'name', control })
  useScrollToError({ ref: countryRef, name: 'countryid', control })
  useScrollToError({ ref: currencyRef, name: 'currencycode', control })

  const onSubmit = async (warehouse: Warehouse) => {
    const { id, store, ...fields } = warehouse

    await addUpdateWarehouseMutation({
      variables: {
        warehouse: {
          ...fields,
          storeid: store?.id || null,
          warehouseid: warehouse.id,
          externalid: warehouse.externalid || '',
        },
      },
    })
  }

  useEffect(() => {
    if (!warehouseId) reset()
    if (data?.warehouse) reset(data?.warehouse)
  }, [data, reset, warehouseId])

  return (
    <SettingsDetailDialog
      type={isNewWarehouse ? 'add' : 'edit'}
      dialogName="warehouse-list"
      typeLabel={t('settings:warehouse.item_type')}
      getItemName={() => getValues('name')}
      onSave={onSubmit}
      onDelete={deleteItem}
      onClose={onClose}
      isLoading={isLoading}
      handleSubmit={handleSubmit}
      formState={formState}
    >
      <SectionHeader variant="transparent" sx={{ p: 0, mt: 2, mb: 1 }}>
        {t('settings:warehouse.general_settings')}
      </SectionHeader>

      <SelectInput
        name="warehousetype"
        control={control}
        options={warehouseType}
        label={t('settings:warehouse.warehouse_type')}
        dataTestid="warehouse-type"
        sx={{ mb: 2 }}
      />

      <SelectInput
        name="usetype"
        control={control}
        options={warehouseUseType}
        label={t('settings:warehouse.usetype')}
        dataTestid="warehouse-type"
        sx={{ mb: 2 }}
      />

      <StoresInput
        stores={storesData?.stores || []}
        isLoading={fetchStoresLoading}
        name="store"
        control={control}
        sx={{ '.MuiFormControl-root': { mb: 2 } }}
      />

      <SwitchInput
        name="sellable"
        control={control}
        label={t('settings:warehouse.sellable')}
        sx={{
          width: '100%',
          justifyContent: 'space-between',
          mb: 2,
        }}
        slotProps={{ typography: { fontWeight: 'medium' } }}
        data-testid="sellable-toggle"
      />

      <TextFieldInput
        name="name"
        innerRef={nameRef}
        control={control}
        required
        label={t('settings:warehouse.name')}
        sx={{ mb: 2 }}
        rules={{ required: true }}
        slotProps={{
          htmlInput: { 'data-testid': 'warehouse-name' },
        }}
      />

      <TextFieldInput
        name="externalid"
        control={control}
        label={t('settings:warehouse.external_id')}
        sx={{ mb: 2 }}
        slotProps={{
          htmlInput: { 'data-testid': 'warehouse-externalid' },
        }}
      />

      <CurrencyInput
        ref={currencyRef}
        name="currencycode"
        control={control}
        required
        sx={{ '.MuiFormControl-root': { mb: 2 } }}
      />

      <SectionHeader variant="transparent" sx={{ p: 0, mt: 2, mb: 1 }}>
        {t('settings:warehouse.location')}
      </SectionHeader>

      <TextFieldInput
        name="address"
        control={control}
        label={t('settings:warehouse.address')}
        sx={{ mb: 2 }}
        slotProps={{
          htmlInput: { 'data-testid': 'warehouse-address' },
        }}
      />

      <TextFieldInput
        name="address2"
        control={control}
        label={t('settings:warehouse.address2')}
        sx={{ mb: 2 }}
        slotProps={{
          htmlInput: { 'data-testid': 'warehouse-address2' },
        }}
      />

      <TextFieldInput
        name="zip"
        control={control}
        label={t('settings:warehouse.zip')}
        sx={{ mb: 2 }}
        slotProps={{
          htmlInput: { 'data-testid': 'warehouse-zip' },
        }}
      />

      <TextFieldInput
        name="city"
        control={control}
        label={t('settings:warehouse.city')}
        sx={{ mb: 2 }}
        slotProps={{
          htmlInput: { 'data-testid': 'warehouse-city' },
        }}
      />

      <TextFieldInput
        name="state"
        control={control}
        label={t('settings:warehouse.state')}
        sx={{ mb: 2 }}
        slotProps={{
          htmlInput: { 'data-testid': 'warehouse-state' },
        }}
      />

      <CountriesInput
        name="countryid"
        control={control}
        isRequired
        sx={{ mb: 2 }}
      />
    </SettingsDetailDialog>
  )
}
