import { useMutation, useQuery } from '@apollo/client'
import { Button } from '@mui/material'
import { Divider, Container } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { FormProvider, SubmitErrorHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { RootRoute } from '../..'
import { PageHeader } from '../../../components/page-header'
import {
  AddSalesTaxGroupDocument,
  SalesTaxGroupDocument,
  UpdateSalesTaxGroupDocument,
} from '../../../generated/graphql'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { useServerValidation } from '../../../hooks/server-validation'
import { useTracking } from '../../../hooks/tracking'
import { getErrorMessages } from '../../../utils/error-mapping'
import { salesTaxGroupsViewPanelVar } from '../sales-tax-groups'
import { DeleteSalesTaxGroupField } from './fields/delete-sales-tax-group-field'
import { InformationField } from './fields/information-field'
import { SalesTaxesField } from './fields/sales-taxes-field'
import { BaseSalesTaxGroupContext, EMPTY_SALES_TAX } from './shared'
import { FormRouteLeavingGuard } from '../../../components/form-route-leaving-guard'

export const SettingsSalesTaxGroupPage = () => {
  const { id: salesTaxGroupId, siteId } = useParams()
  const isNewSalesTaxGroup = !salesTaxGroupId
  const { t } = useTranslation(['shared', 'settings'])
  const { trackButtonClick, trackFormSuccess, trackFormError } = useTracking()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const generatePath = useAbsolutePath()

  const { data: salesTaxGroupData, loading: isSalesTaxDataLoading } = useQuery(
    SalesTaxGroupDocument,
    {
      variables: { salesTaxGroupId: Number(salesTaxGroupId) },
      skip: !salesTaxGroupId,
    },
  )

  const [updateSalesTaxGroup, { loading: isUpdatingSalesTaxGroup }] =
    useMutation(UpdateSalesTaxGroupDocument)

  const [addSalesTaxGroup, { loading: isAddingSalesTaxGroup }] = useMutation(
    AddSalesTaxGroupDocument,
  )

  const formContext = useForm<BaseSalesTaxGroupContext>({
    defaultValues: { salestaxes: [EMPTY_SALES_TAX] },
  })

  const setFormError = useServerValidation<BaseSalesTaxGroupContext>(
    'settings:sales_tax_group',
    formContext.setError,
    { resolveFieldFromProperty: (property) => property },
  )

  const onSubmit = async (submittedData: BaseSalesTaxGroupContext) => {
    try {
      let salesTaxGroupIdToOpen = salesTaxGroupId
      const {
        salestaxes: submittedSalesTaxes,
        id: submittedSalesTaxGroupId,
        ...rest
      } = submittedData

      const salesTaxIds = submittedSalesTaxes
        .filter((salesTax) => salesTax.id !== -1)
        .map((salesTax) => ({ salestaxid: salesTax.id }))

      if (!isNewSalesTaxGroup) {
        await updateSalesTaxGroup({
          variables: {
            data: { ...rest, salestaxes: salesTaxIds },
            updateSalesTaxGroupId: submittedSalesTaxGroupId,
          },
        })
        trackButtonClick({ name: 'sales-tax-group-save' })
      } else {
        const { data } = await addSalesTaxGroup({
          variables: {
            data: { ...rest, salestaxes: salesTaxIds },
          },
        })

        salesTaxGroupIdToOpen = String(data?.addSalesTaxGroup.id)
        trackButtonClick({ name: 'sales-tax-group-add' })
      }

      trackFormSuccess({ name: 'sales-tax-group' })

      salesTaxGroupsViewPanelVar({
        isOpen: true,
        salesTaxGroupId: Number(salesTaxGroupIdToOpen),
      })

      enqueueSnackbar(
        t(
          isNewSalesTaxGroup
            ? 'settings:sales_tax_group.sales_tax_group_message.success_add'
            : 'settings:sales_tax_group.sales_tax_group_message.success_update',
          { count: 1 },
        ),
      )

      void navigate(generatePath(RootRoute.SettingsSalesTaxGroups))
    } catch (error) {
      setFormError(error)

      const errorMessage = getErrorMessages(error, {
        resolve: (errorCode) => {
          return `settings:sales_tax_group.error.${errorCode}`
        },
        fallbackError: t(
          isNewSalesTaxGroup
            ? 'settings:sales_tax_group.sales_tax_group_message.failure_add'
            : 'settings:sales_tax_group.sales_tax_group_message.failure_update',
        ),
      }).join(', ')

      trackFormError({
        name: 'sales-tax-group',
        errorMessage,
      })

      enqueueSnackbar(errorMessage, { variant: 'error' })
    }
  }

  const onError: SubmitErrorHandler<BaseSalesTaxGroupContext> = (_errors) => {
    enqueueSnackbar(t('settings:sales_tax_group.error.generic'), {
      variant: 'error',
    })
  }

  useEffect(() => {
    if (salesTaxGroupData) {
      const { salesTaxGroup } = salesTaxGroupData
      const salestaxes =
        salesTaxGroup?.salestaxes?.length > 0
          ? salesTaxGroup.salestaxes
          : [EMPTY_SALES_TAX]
      formContext.reset({ ...salesTaxGroup, salestaxes })
    }
  }, [formContext, salesTaxGroupData])

  const isLoading =
    isSalesTaxDataLoading || isAddingSalesTaxGroup || isUpdatingSalesTaxGroup

  return (
    <>
      <PageHeader
        title={t(
          isNewSalesTaxGroup
            ? 'settings:sales_tax_group.add_sales_tax_group'
            : 'settings:sales_tax_group.edit_sales_tax_group',
        )}
        backTo={generatePath(RootRoute.SettingsSalesTaxGroups, {
          siteId: siteId,
        })}
        backText={t('settings:sales_tax_group.page_title_groups')}
        showBackButton={true}
        isSticky
        rightColumn={
          <Button
            loading={isLoading}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={formContext.handleSubmit(onSubmit, onError)}
            data-testid="submit-sales-tax-group-form-button"
          >
            {t(isNewSalesTaxGroup ? 'shared:action.add' : 'shared:action.save')}
          </Button>
        }
      />

      <Container data-testid="sales-tax-group-page">
        <FormProvider {...formContext}>
          <FormRouteLeavingGuard />
          <InformationField />
          <Divider />
          <SalesTaxesField />
          <Divider />
          <DeleteSalesTaxGroupField salesTaxGroupId={salesTaxGroupId} />
        </FormProvider>
      </Container>
    </>
  )
}
