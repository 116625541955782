import {
  LazyQueryExecFunction,
  MutationFunction,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client'
import { enqueueSnackbar } from 'notistack'
import { createContext, ReactNode, useEffect, useMemo } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useParams, useSearchParams } from 'react-router'
import {
  AddReportDocument,
  AddReportJobDocument,
  AddReportMutation,
  CashRegisterFragment,
  DeleteReportDocument,
  DeleteReportMutation,
  FavoriteReportsDocument,
  LatestReportJobsDocument,
  Report,
  ReportExtendedDocument,
  ReportInput,
  ReportJobLangidEnum,
  ReportJobsDocument,
  ReportJobsQuery,
  ReportJobState,
  ReportResults,
  ReportResultsDocument,
  ReportResultsQuery,
  ReportsDocument,
  WarehouseFragment,
} from '../../generated/graphql'
import { useFormFilter } from '../../hooks/form-filter'
import { useMe } from '../../hooks/me'
import { getErrorMessages } from '../../utils/error-mapping'
import { stripNullValues } from '../../utils/strip-null-values'
import { ArrayElement } from '../../utils/types'

export type ReportsContextType = {
  onAddReportJob: (report: Report) => Promise<void>
  isAddingReportJob: boolean
  report?: Report
  allWarehouses: WarehouseFragment[]
  allCashRegisters: CashRegisterFragment[]
  isLoadingReport: boolean
  fetchReportResults?: LazyQueryExecFunction<
    ReportResultsQuery,
    {
      jobId: number
    }
  >
  reportResults?: ReportResults
  isLoadingReportResults: boolean
  reportJobs?: ArrayElement<ReportJobsQuery['reportJobs']['items']>[]
  isLoadingReportJobs: boolean
  reportJob?: ArrayElement<ReportJobsQuery['reportJobs']['items']>
  addReport: MutationFunction<AddReportMutation, { report: ReportInput }>
  deleteReport: MutationFunction<DeleteReportMutation, { reportId: number }>
  reportJobFormContext: UseFormReturn<ReportJobFormContext>
}

export type ReportJobFormContext = {
  reportJobId?: string
}

export const ReportsContext = createContext<ReportsContextType | undefined>(
  undefined,
)

export const ReportsProvider = ({ children }: { children: ReactNode }) => {
  const { id } = useParams()
  const [searchParams] = useSearchParams()
  const reportId = Number(id)
  const reportJobId = searchParams.get('reportJobId')

  const { me } = useMe()

  const { formContext, applyFilter } = useFormFilter<ReportJobFormContext>({
    formProps: {
      defaultValues: { reportJobId: reportJobId || '' },
    },
  })

  const { data: reportData, loading: isLoadingReport } = useQuery(
    ReportExtendedDocument,
    {
      variables: {
        reportId,
      },
    },
  )

  const {
    data: reportJobs,
    loading: isLoadingReportJobs,
    refetch: refetchReportJobs,
    startPolling: startPollingJobs,
    stopPolling: stopPollingJobs,
  } = useQuery(ReportJobsDocument, {
    variables: {
      reportid: reportId,
    },
  })

  const [
    fetchReportResults,
    { data: reportResults, loading: isLoadingReportResults },
  ] = useLazyQuery(ReportResultsDocument, {
    variables: { jobId: Number(reportJobId) },
  })

  const [addReportJobMutation, { loading: isAddingReportJob }] = useMutation(
    AddReportJobDocument,
    {
      refetchQueries: [LatestReportJobsDocument],
    },
  )

  const [addReport] = useMutation(AddReportDocument, {
    refetchQueries: [ReportsDocument, FavoriteReportsDocument],
  })
  const [deleteReport] = useMutation(DeleteReportDocument, {
    refetchQueries: [ReportsDocument, FavoriteReportsDocument],
  })

  const onAddReportJob = async (report: Report) => {
    try {
      const newReportJob = await addReportJobMutation({
        variables: {
          reportJob: {
            reportid: report.reportid,
            reporttype: report.reporttype,
            reportdefinition: stripNullValues(report.reportdefinition),
            langid: ReportJobLangidEnum.En,
            timezone: me?.timeZone || '',
            jobname: report.reportname,
          },
        },
      })
      const newReportJobId = newReportJob.data?.addReportJob?.jobid
      void refetchReportJobs({ reportid: reportId })
      if (newReportJobId) {
        formContext.setValue('reportJobId', String(newReportJobId))
        void applyFilter()
      }
    } catch (error) {
      const errorMessages = getErrorMessages(error)
      enqueueSnackbar({ message: errorMessages[0], variant: 'error' })
    }
  }

  const shouldPollReportJobs = useMemo(() => {
    if (!reportJobs?.reportJobs.items) return false
    return reportJobs?.reportJobs.items.some((job) =>
      [ReportJobState.InProgress, ReportJobState.Pending].includes(
        job.jobstate,
      ),
    )
  }, [reportJobs?.reportJobs.items])

  const reportJob = useMemo(
    () =>
      reportJobs?.reportJobs.items?.find(
        (job) => job.jobid === Number(reportJobId),
      ),
    [reportJobId, reportJobs?.reportJobs.items],
  )

  useEffect(() => {
    if (shouldPollReportJobs) {
      startPollingJobs(3000)
    } else {
      stopPollingJobs()
    }
  }, [shouldPollReportJobs, startPollingJobs, stopPollingJobs])

  useEffect(() => {
    if (reportJob?.jobstate === ReportJobState.Finished) {
      void fetchReportResults({ variables: { jobId: Number(reportJobId) } })
    }
  }, [fetchReportResults, reportJob, reportJobId])

  return (
    <ReportsContext.Provider
      value={{
        reportJobFormContext: formContext,
        reportJob: reportJob,
        reportJobs: reportJobs?.reportJobs.items ?? [],
        allWarehouses: reportData?.allWarehouses ?? [],
        allCashRegisters: reportData?.allCashRegisters ?? [],
        isLoadingReportJobs,
        fetchReportResults,
        reportResults: reportResults?.reportResults,
        isLoadingReportResults,
        report: reportData?.report,
        isLoadingReport,
        onAddReportJob,
        isAddingReportJob,
        addReport,
        deleteReport,
      }}
    >
      {children}
    </ReportsContext.Provider>
  )
}
