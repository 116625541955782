import { List, Paper } from '@mui/material'
import { SectionHeader } from '@sitoo/mui-components'

type Props = {
  title: string
  children: React.ReactNode
}

export const SettingsListSection = ({ title, children }: Props) => {
  return (
    <Paper elevation={0} sx={{ mb: 2, pb: 1.5 }}>
      <SectionHeader sx={{ px: 2 }} variant="transparent">
        {title}
      </SectionHeader>
      <List sx={{ '& .MuiListItem-root:last-of-type': { pb: 0 } }}>
        {children}
      </List>
    </Paper>
  )
}
