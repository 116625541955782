import { ListItem } from '@mui/material'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Form } from '..'
import { ReportDefinitionFilter } from '../../../../generated/graphql'
import { WarehousesInput } from '../../../../inputs/warehouses-input'

type Props = {
  warehouseFilterKey: keyof Pick<
    ReportDefinitionFilter,
    'warehouseId' | 'orderWarehouseId'
  >
}
export const WarehouseInput = (props: Props) => {
  const { control } = useFormContext<Form>()
  const { t } = useTranslation('reports')

  const isWarehouseRequired = useWatch({
    control,
    name: 'forcewarehousefilter',
  })

  const rules = isWarehouseRequired
    ? { required: t('validation.required') }
    : undefined

  return (
    <ListItem>
      <WarehousesInput
        name={`reportdefinition.filters.${props.warehouseFilterKey}`}
        control={control}
        rules={rules}
        label={t('edit.warehouse')}
        textFieldProps={{ placeholder: t('edit.include_all') }}
      />
    </ListItem>
  )
}
