import { debounce, TextField, TextFieldProps, useForkRef } from '@mui/material'
import { ChangeEvent, Ref, useCallback, useMemo, useRef } from 'react'
import {
  FieldPath,
  FieldValues,
  PathValue,
  useController,
  UseControllerProps,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useTracking } from '../../hooks/tracking'

type Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  TValue = string | number,
> = UseControllerProps<TFieldValues> &
  Omit<TextFieldProps, 'name'> & {
    innerRef?: Ref<HTMLElement> // TODO: React 19 allows passing ref as a prop

    transform?: {
      input?: (value: PathValue<TFieldValues, TName>) => TValue
      output?: (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      ) => PathValue<TFieldValues, TName>
    }
  }

export const TextFieldInput = <TFieldValues extends FieldValues>(
  props: Props<TFieldValues>,
) => {
  const { t } = useTranslation(['shared'])

  const { trackInputChange } = useTracking()

  const { name, control, rules, innerRef, ...textFieldProps } = props

  const validationRules = {
    ...rules,
    required: props.required
      ? t('shared:validation.field_required', { field: props.label ?? name })
      : undefined,
  }

  const { field, formState, fieldState } = useController({
    name,
    control,
    rules: validationRules,
  })

  const isSubmitting = formState.isSubmitting

  const formError = fieldState.error

  const isRequired = rules?.required === true || props.required

  const inputRef = useRef<HTMLInputElement>(null)

  const handleInputRef = useForkRef(field.ref, innerRef || inputRef)

  const trackChangeEvent = useMemo(
    () =>
      debounce((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        trackInputChange({ name, value: event.target.value })(event)
      }, 1000),
    [name, trackInputChange],
  )

  const value = useMemo(() => {
    const fieldValue = props.transform?.input
      ? props.transform.input(field.value)
      : field.value

    return fieldValue
  }, [field.value, props.transform])

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const fieldValue = props.transform?.output
        ? props.transform.output(event)
        : event.target.value

      event.target.value = fieldValue

      field.onChange(event)
      props.onChange?.(event)
      trackChangeEvent(event)
    },
    [field, props, trackChangeEvent],
  )

  return (
    <TextField
      {...textFieldProps}
      required={isRequired}
      value={value}
      onChange={onChange}
      onFocus={(event) => props.onFocus?.(event)}
      onBlur={(event) => props.onBlur?.(event)}
      error={!!formError}
      name={field.name}
      disabled={isSubmitting || textFieldProps.disabled}
      helperText={formError?.message ?? textFieldProps.helperText}
      ref={inputRef}
      inputRef={handleInputRef}
    />
  )
}
