import { Menu, MenuItem, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useTracking } from '../../../hooks/tracking'
import { nanoid } from 'nanoid'
import { RootRoute } from '../..'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { useMutation } from '@apollo/client'
import { ExportOrdersDocument, FileType } from '../../../generated/graphql'
import { exportOrdersLoadingVar } from '../orders-list'
import { ExportDialog } from '../../../components/export-dialog'
import { useState } from 'react'
import { useSnackbar } from 'notistack'

type Props = {
  anchorEl: Element | null
  isOpen: boolean
  onClose?(): void
  selectedIds: number[]
}

export const OrderContextMenu = (props: Props) => {
  const { t } = useTranslation(['shared', 'orders'])
  const { trackButtonClickEvent } = useTracking()
  const generatePath = useAbsolutePath()
  const [isExportDialogOpen, setExportDialogOpen] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [exportOrdersMutation, { loading: isLoading }] =
    useMutation(ExportOrdersDocument)

  const printOrders = () => {
    const printId = nanoid()

    sessionStorage.setItem(
      printId,
      JSON.stringify({ selectedIds: props.selectedIds }),
    )

    window.open(generatePath(RootRoute.OrdersPrint, { id: printId }), '_blank')
  }

  const onExport = async (fileType: FileType) => {
    try {
      exportOrdersLoadingVar(true)

      const { data } = await exportOrdersMutation({
        variables: {
          orderIds: props.selectedIds,
          fileType,
        },
      })

      const fileLink = data?.exportOrders

      if (fileLink) {
        window.location.href = fileLink
      }
      enqueueSnackbar(t('orders:export_success'), { variant: 'success' })
    } catch (error) {
      enqueueSnackbar(t('orders:export_error'), { variant: 'error' })
    } finally {
      setExportDialogOpen(false)
      exportOrdersLoadingVar(false)
    }
  }

  return (
    <>
      <Menu
        id="orders-list-bulk-actionsmenu"
        anchorEl={props.anchorEl}
        open={props.isOpen}
        onClose={props.onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        MenuListProps={{
          'aria-labelledby': 'orders-list-bulk-actionsmenu-button',
        }}
      >
        <MenuItem
          data-testid="orders-bulk-print-action"
          onClick={trackButtonClickEvent(
            { name: 'orders-list-bulk-actions-menu-print-order' },
            () => {
              printOrders()
              props.onClose?.()
            },
          )}
        >
          <Typography variant="body02">
            {t('orders:bulk_actions.print_order', {
              count: props.selectedIds.length,
            })}
          </Typography>
        </MenuItem>

        <MenuItem
          data-testid="orders-bulk-export-action"
          onClick={trackButtonClickEvent(
            { name: 'orders-list-bulk-actions-menu-export-order' },
            () => {
              setExportDialogOpen(true)
              props.onClose?.()
            },
          )}
        >
          <Typography variant="body02">
            {t('orders:bulk_actions.export_selected_order', {
              count: props.selectedIds.length,
            })}
          </Typography>
        </MenuItem>
      </Menu>

      <ExportDialog
        open={isExportDialogOpen}
        onClose={() => setExportDialogOpen(false)}
        label={t('orders:bulk_actions.export_selected_order', {
          count: props.selectedIds.length,
        })}
        onSuccess={onExport}
        isLoading={isLoading}
      />
    </>
  )
}
