import { makeVar, useReactiveVar } from '@apollo/client'
import { Button, Grid2 as Grid, Tab, Tabs } from '@mui/material'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { RootRoute } from '..'
import { FilterProvider } from '../../components/data-grid/context'
import { PageHeader } from '../../components/page-header'
import { useAbsolutePath } from '../../hooks/absolute-path'
import { usePageTitle } from '../../hooks/title'
import { useTracking } from '../../hooks/tracking'
import { ReportListFilterPanel } from './all/report-list-filter-panel'

export const reportListFilterPanelVar = makeVar({
  isOpen: false,
})

export const ReportsPage = () => {
  const { t } = useTranslation(['reports'])
  const navigate = useNavigate()
  const location = useLocation()
  const generatePath = useAbsolutePath()
  usePageTitle(t('reports:page_title'))

  const filterViewPanelState = useReactiveVar(reportListFilterPanelVar)
  const { trackButtonClick } = useTracking()

  const closeFilterPanel = () => {
    trackButtonClick({ name: 'reports-filter-panel-close' })
    reportListFilterPanelVar({ isOpen: false })
  }

  useEffect(() => {
    if (!location.pathname.includes(generatePath(RootRoute.ReportsAll))) {
      reportListFilterPanelVar({ isOpen: false })
    }
  }, [generatePath, location])

  return (
    <FilterProvider>
      <Grid container>
        <Grid size="grow">
          <PageHeader
            title={t('reports:page_title')}
            isFlexible
            rightColumn={
              <Button
                onClick={() =>
                  void navigate(generatePath(RootRoute.ReportsNew))
                }
              >
                {t('shared:action.create')}
              </Button>
            }
          >
            <Tabs
              onChange={(_, route) => void navigate(route)}
              value={location.pathname}
              variant="standard"
              sx={{
                '.MuiButtonBase-root': {
                  borderColor: 'transparent',
                },
              }}
            >
              <Tab
                label={t('reports:overview')}
                value={generatePath(RootRoute.Reports)}
              />
              <Tab
                label={t('reports:all_reports')}
                value={generatePath(RootRoute.ReportsAll)}
              />
            </Tabs>
          </PageHeader>
          <Outlet />
        </Grid>
        <Grid>
          <ReportListFilterPanel
            isOpen={filterViewPanelState.isOpen}
            onClose={closeFilterPanel}
          />
        </Grid>
      </Grid>
    </FilterProvider>
  )
}
