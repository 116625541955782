import { Settings } from '../../../../generated/graphql'
import { formatDecimal } from '../../../../utils/format/number'

export const formatCurrencyRates = (rates?: Settings['currency_rates']) =>
  rates
    ?.map(
      (item) =>
        `${item.code} ${formatDecimal(item.rate, {
          maxDecimals: 8,
          minDecimals: 0,
          useGrouping: false,
        })}`,
    )
    .join('\n') || ''
