import { ListItem } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Form } from '..'

import { ProductGroupTypeInput } from '../../../../inputs/product-group-type-input'

export const ProductGroupTypesInput = () => {
  const { control } = useFormContext<Form>()
  const { t } = useTranslation('reports')

  return (
    <ListItem>
      <ProductGroupTypeInput
        name={`reportdefinition.filters.productProductGroupType`}
        control={control}
        label={t('edit.product_group_type')}
        displayEmpty
      />
    </ListItem>
  )
}
