import {
  Box,
  Chip,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { CheckIcon } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import { ReportSummaryFragment } from '../../../../generated/graphql'

type Props = {
  report: ReportSummaryFragment
  isSelected: boolean
  onSelect: (reportId: number) => void
}

export const ReportListItem = ({ report, isSelected, onSelect }: Props) => {
  const { t } = useTranslation('reports')
  return (
    <ListItemButton
      divider
      selected={isSelected}
      sx={{
        cursor: 'pointer',
        paddingBottom: (theme) => theme.spacing(1),
      }}
      onClick={() => onSelect(report.reportid)}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }}
      >
        <ListItemText
          primary={report.reportname}
          secondary={report.reportdescription}
          sx={{ flexGrow: 0 }}
        />
        <Box sx={{ mt: 0.5, flexGrow: 0 }}>
          <Chip
            label={t(`reports:report_types.${report.reporttype}`)}
            color="gray"
            size="small"
          />
        </Box>
      </Box>
      <ListItemIcon
        sx={{
          visibility: isSelected ? 'visible' : 'hidden',
        }}
      >
        <CheckIcon />
      </ListItemIcon>
    </ListItemButton>
  )
}
