/* eslint-disable @typescript-eslint/no-misused-promises */
import { useReactiveVar } from '@apollo/client'
import {
  Box,
  Chip,
  CircularProgress,
  Container,
  Divider,
  Grid2 as Grid,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material'
import { ConfirmationDialog } from '@sitoo/mui-components'
import { enqueueSnackbar } from 'notistack'
import { useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { RootRoute } from '..'
import { MoreButton } from '../../components/more-button'
import { PageHeader } from '../../components/page-header'
import { ReportJobState } from '../../generated/graphql'
import { useAbsolutePath } from '../../hooks/absolute-path'
import { usePageTitle } from '../../hooks/title'
import { getErrorMessages } from '../../utils/error-mapping'
import { stripNullValues } from '../../utils/strip-null-values'
import { EditReportViewPanel } from './edit-report-view-panel'
import { FavoriteReportToggleButton } from './favorites/favorite-report-toggle-button'
import { GraphSection } from './graph-section'
import { NavigationSection } from './navigation-section'
import { NoReportGenerated } from './no-report-generated'
import {
  ReportsProvider,
  ReportsContext,
  ReportsContextType,
} from './reports-context'
import { TableSection } from './table-section'
import { editReportViewPanelVar } from './variables'

export const ReportPage = () => {
  return (
    <ReportsProvider>
      <Report />
    </ReportsProvider>
  )
}

export const Report = () => {
  const params = useParams<{ id: string }>()
  const reportId = Number(params.id)
  const [searchParams] = useSearchParams()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const editReportViewPanelState = useReactiveVar(editReportViewPanelVar)

  const generatePath = useAbsolutePath()
  const navigate = useNavigate()
  const { t } = useTranslation(['shared', 'reports'])
  usePageTitle(t('reports:report_page_title'))

  const {
    report,
    reportResults,
    reportJobs,
    reportJob,
    isLoadingReport,
    isLoadingReportResults,
    addReport,
    deleteReport,
    allCashRegisters,
    allWarehouses,
  } = useContext(ReportsContext) as ReportsContextType

  const isCustomReport = (report?.reportid ?? 0) > 0
  const reportJobExists = !!reportJobs?.length

  const anyReportJobInProgress = useMemo(
    () =>
      reportJobs?.some((job) =>
        [ReportJobState.InProgress, ReportJobState.Pending].includes(
          job.jobstate,
        ),
      ),
    [reportJobs],
  )
  const isLoadingCurrentReport = isLoadingReport || isLoadingReportResults

  const currentReportJobInProgress = useMemo(() => {
    if (isLoadingCurrentReport) return true

    return reportJob?.jobstate
      ? [ReportJobState.Pending, ReportJobState.InProgress].includes(
          reportJob?.jobstate,
        )
      : false
  }, [isLoadingCurrentReport, reportJob])

  const handleMoreButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMoreMenuClose = () => setAnchorEl(null)

  const handleCloneReport = async () => {
    if (!report) return
    try {
      const { reportid, ...rest } = report
      const { data } = await addReport({
        variables: {
          report: {
            ...stripNullValues(rest),
            reportname: t('reports:copy_of', { name: report.reportname }),
          },
        },
      })
      const newReportId = data?.addReport?.reportid
      void navigate(generatePath(RootRoute.Report, { id: String(newReportId) }))
      editReportViewPanelVar({ isOpen: true })
    } catch (error) {
      const errorMessages = getErrorMessages(error)
      enqueueSnackbar({ message: errorMessages[0], variant: 'error' })
    }
  }

  const handleDeleteReport = async () => {
    try {
      await deleteReport({ variables: { reportId: Number(reportId) } })
      void navigate(generatePath(RootRoute.Reports, { replace: true }))
    } catch (error) {
      const errorMessages = getErrorMessages(error)
      enqueueSnackbar({ message: errorMessages[0], variant: 'error' })
      setDeleteDialogOpen(false)
    }
  }

  const hasGeneratedReport = reportJobExists || currentReportJobInProgress

  return (
    <Grid container>
      <ConfirmationDialog
        confirmAction={handleDeleteReport}
        title={t('reports:dialog_delete_label')}
        text={t('reports:dialog_delete_description')}
        variant="destructive"
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      />
      <Grid size="grow">
        {anyReportJobInProgress && (
          <Box
            sx={{
              width: '100%',
              bgcolor: (theme) => theme.palette.blueBase,
              color: (theme) => theme.palette.white,
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            <Typography variant="caption">
              {t('reports:report_generation_in_progress')}
            </Typography>
          </Box>
        )}
        <PageHeader
          title={report?.reportname || ''}
          isFlexible
          backTo={searchParams.get('backTo') ?? generatePath(RootRoute.Reports)}
          showBackButton={true}
          subTitle={
            <Chip
              label={
                isCustomReport
                  ? t('reports:custom')
                  : t('reports:system_template')
              }
              color={isCustomReport ? 'black' : 'gray'}
              size="small"
            />
          }
          rightColumn={
            <>
              {isCustomReport && (
                <FavoriteReportToggleButton reportId={reportId} />
              )}
              <MoreButton onClick={handleMoreButtonClick} />
            </>
          }
        />
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          data-testid="more-options-button"
          onClick={handleMoreMenuClose}
          onClose={handleMoreMenuClose}
        >
          {isCustomReport && (
            <MenuItem
              onClick={() => {
                editReportViewPanelVar({ isOpen: true })
              }}
            >
              {t('reports:edit_report')}
            </MenuItem>
          )}

          <MenuItem onClick={handleCloneReport}>
            {t('reports:make_a_copy_edit')}
          </MenuItem>

          {isCustomReport && (
            <MenuItem onClick={() => setDeleteDialogOpen(true)}>
              {t('reports:delete_report')}
            </MenuItem>
          )}
        </Menu>
        <Container maxWidth={false}>
          <NavigationSection
            report={report}
            reportJob={reportJob}
            reportJobExists={reportJobExists}
            allCashRegisters={allCashRegisters}
            allWarehouses={allWarehouses}
          />

          {report && !hasGeneratedReport && (
            <NoReportGenerated report={report} />
          )}

          {currentReportJobInProgress && (
            <>
              <Divider />
              <Box
                sx={{
                  width: '100%',
                  height: '60vh', // TODO: replace hardcoded value
                  zIndex: 10,
                  backgroundColor: 'white',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Stack
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                  }}
                >
                  <CircularProgress sx={{ mb: 2 }} />
                  <Typography variant="body02">
                    {t('reports:report_in_progress')}
                  </Typography>
                  <Typography maxWidth="250px" variant="body01">
                    {isLoadingCurrentReport
                      ? t('reports:report_loading_description')
                      : t('reports:report_in_progress_description')}
                  </Typography>
                </Stack>
              </Box>
            </>
          )}

          {reportJobExists && !currentReportJobInProgress && (
            <>
              <GraphSection reportResults={reportResults} />
              <TableSection
                reportName={report?.reportname}
                reportJob={reportJob}
                reportResults={reportResults}
              />
            </>
          )}
        </Container>
      </Grid>
      <Grid>
        <EditReportViewPanel
          isOpen={isCustomReport && editReportViewPanelState.isOpen}
          onClose={() => {
            editReportViewPanelVar({ isOpen: false })
          }}
          reportId={Number(reportId)}
        />
      </Grid>
    </Grid>
  )
}
