import {
  MenuItem,
  SxProps,
  TextField,
  TextFieldProps,
  Theme,
} from '@mui/material'
import {
  FieldValues,
  useController,
  UseControllerProps,
  PathValue,
  FieldPath,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useTracking } from '../../hooks/tracking'
import { ChangeEvent, useMemo, useCallback } from 'react'

type Value = string | number | boolean

export type Option<T extends Value = Value> = {
  name: string
  value: T
  disabled?: boolean
}

type Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  TValue = Option | string | unknown,
> = UseControllerProps<TFieldValues, TName> & {
  options: Option[]
  label?: string
  helperText?: string
  dataTestid?: string
  slotProps?: TextFieldProps['slotProps']
  sx?: SxProps<Theme>
  required?: boolean
  transform?: {
    input?: (value: PathValue<TFieldValues, TName>) => TValue
    output?: (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => PathValue<TFieldValues, TName>
  }
  onChange?: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void
}

export const SelectInput = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  TValue = Option | string | unknown,
>(
  props: Props<TFieldValues, TName, TValue>,
) => {
  const { t } = useTranslation(['shared'])
  const { trackInputChange } = useTracking()
  const { control, name, options, label, helperText, dataTestid, sx } = props
  const { disabled, slotProps, rules, required } = props

  const validationRules = {
    required: required
      ? t('shared:validation.field_required', { field: label ?? name })
      : undefined,
    ...rules,
  }

  const { formState, fieldState, field } = useController({
    name,
    control,
    rules: validationRules,
  })

  const { isSubmitting } = formState
  const formError = fieldState.error
  const isRequired = !!validationRules?.required

  const value = useMemo(() => {
    const fieldValue = props.transform?.input
      ? props.transform.input(field.value)
      : field.value

    return fieldValue
  }, [props.transform, field.value])

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const fieldValue = props.transform?.output
        ? props.transform.output(event)
        : event.target.value

      event.target.value = fieldValue

      field.onChange(event)
      props?.onChange?.(event)
      trackInputChange({ name, value: fieldValue })(event)
    },
    [props, field, trackInputChange, name],
  )

  return (
    <TextField
      {...field}
      value={value}
      data-testid={dataTestid}
      disabled={disabled || isSubmitting}
      select
      label={label}
      error={!!formError}
      helperText={formError?.message ?? helperText}
      required={isRequired}
      onChange={onChange}
      slotProps={{
        ...slotProps,
        htmlInput: {
          ['data-testid']: `select-input-${name}`,
          ...slotProps?.htmlInput,
        },
      }}
      sx={sx}
    >
      {options.map(({ name, value, disabled }) => (
        /*
         * MenuItem infers `value` type from LiHtmlAttributes<HTMLLIElement>
         * which does not accept `boolean`. However, the react-hook-form can
         * parse it without any issues. So we ignore the type error here.
         */
        <MenuItem
          value={value as Exclude<Value, boolean>}
          key={String(value)}
          disabled={disabled ?? undefined}
        >
          {name}
        </MenuItem>
      ))}
    </TextField>
  )
}
