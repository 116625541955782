import { useTranslation } from 'react-i18next'
import { Settings } from '.'
import { FormProvider, useForm } from 'react-hook-form'
import { PageHeader } from '../../../components/page-header'
import { Button, Container } from '@mui/material'
import { CurrencyAndTimezone } from './sections/currency-and-timezone'
import { getDirtyValues } from '../../../utils/dirty-fields'
import { FormRouteLeavingGuard } from '../../../components/form-route-leaving-guard'
import { stripTypename } from '@apollo/client/utilities'
import { formatCurrencyRates } from './utils/format-currency-rates'
import { PosRequiredFields } from './sections/pos-required-fields'
import { LimitPaymentOptions } from './sections/limit-payment-options'
import { DigitalReceipt } from './sections/digital-receipt'
import { WarehouseSettings } from './sections/warehouse'

type AdditionalFormFields = {
  currency_rates_data: GeneralSettingsFormContext['currency_rates'] | string
}
export type GeneralSettingsFormContext = Settings & AdditionalFormFields

type Props = {
  onSubmit: (
    fieldsToUpdate: Partial<GeneralSettingsFormContext>,
  ) => Promise<Settings | undefined>
  settings: Settings | undefined
  formId: string
}

export const GeneralSettingsForm = ({ onSubmit, settings, formId }: Props) => {
  const { t } = useTranslation(['shared', 'settings'])
  const showWIPSections = false

  const defaultValues: GeneralSettingsFormContext = {
    currencycode: '',
    timezone: '',
    currency_rates: [],
    currency_rates_data: [],
    required_fields_booking: [],
    required_fields_delivery: [],
    required_fields_pickup: [],
    payments_refund_manual: [],
    payments_refund_validated: [],
    payments_refund_validated_omni: [],
    payments_sales_gift_card: [],
    payments_sales_non_delivered: [],
    emailfrom: '',
    emailfromdisplay: '',
    warehouseid: 0,
    numdaysreserved: 0,
  }

  const formContext = useForm<GeneralSettingsFormContext>({
    defaultValues,
    values: {
      ...settings,
      currency_rates_data: formatCurrencyRates(settings?.currency_rates),
      currency_rates: stripTypename(settings?.currency_rates),
    },
  })

  const handleSubmit = async (formData: GeneralSettingsFormContext) => {
    try {
      const dirtyFieldsRecord = formContext.formState.dirtyFields
      const { currency_rates_data, ...fieldsToUpdate } = getDirtyValues(
        dirtyFieldsRecord,
        formData,
      )

      await onSubmit(fieldsToUpdate)
    } catch (error) {
      // Do nothing, handled by onSubmit
    }
  }

  const onFormSubmit = (e: React.FormEvent): void => {
    formContext.clearErrors()
    void formContext.handleSubmit(handleSubmit)(e)
  }

  return (
    <FormProvider {...formContext}>
      <FormRouteLeavingGuard />
      <PageHeader
        title={t('shared:menu.settings')}
        isSticky
        rightColumn={
          <Button
            type="submit"
            disabled={!formContext.formState.isDirty}
            form={formId}
          >
            {t('shared:action.update')}
          </Button>
        }
      />

      <form id={formId} onSubmit={onFormSubmit}>
        <Container>
          <CurrencyAndTimezone />
          <DigitalReceipt />
          <WarehouseSettings />
          {showWIPSections && <LimitPaymentOptions />}
          {showWIPSections && <PosRequiredFields />}
        </Container>
      </form>
    </FormProvider>
  )
}
