import { useFormContext } from 'react-hook-form'
import { DatePickerInput } from '../../../../inputs/date-picker-input'
import { Form } from '..'
import { useTranslation } from 'react-i18next'
import { ReportDefinitionFilter } from '../../../../generated/graphql'
import { ListItem } from '@mui/material'
type Props = {
  dateEndKey: keyof Pick<ReportDefinitionFilter, 'stockDateEnd'>
}
export const SingleDateInput = (props: Props) => {
  const { control } = useFormContext<Form>()
  const { t } = useTranslation('reports')

  return (
    <ListItem>
      <DatePickerInput
        control={control}
        label={t('edit.date_to')}
        name={`reportdefinition.filters.${props.dateEndKey}`}
      />
    </ListItem>
  )
}
