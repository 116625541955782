import { useTranslation } from 'react-i18next'
import { ReportFragment } from '../../../../generated/graphql'
import { GraphSection } from '../../graph-section'

type Props = {
  report: ReportFragment
}

const decapitalize = (str: string) => str.charAt(0).toLowerCase() + str.slice(1)

export const GraphSectionPreview = ({ report }: Props) => {
  const { t } = useTranslation('reports')
  const firstGroup = decapitalize(report.reportdefinition.groups[0] ?? '')
  const firstMeasure = decapitalize(report.reportdefinition.measures[0] ?? '')

  return (
    <GraphSection
      previewMode
      defaultExpanded
      reportResults={{
        columns: [{ key: firstGroup }, { key: firstMeasure }],
        rows: Array.from({ length: 10 }, (_, index) => ({
          [firstGroup]: t('create.preview_table_label', { number: index + 1 }),
          [firstMeasure]: 10,
        })),
        totals: {},
      }}
    />
  )
}
