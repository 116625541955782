import {
  Box,
  Card,
  CardActionArea,
  Chip,
  Grid2 as Grid,
  List,
  ListItem,
  ListItemText,
  Stack,
} from '@mui/material'
import { ChevronRightIcon } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import { createSearchParams, Link, useLocation } from 'react-router'
import { RootRoute } from '../..'
import { Maybe, ReportJobState } from '../../../generated/graphql'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { useLocalizedDate } from '../../../hooks/localized-date'

type ReportData = {
  jobname?: string
  reporttype?: string
  reportid?: number | null
  jobid?: number
  reportdescription?: string | null
  jobstate?: ReportJobState
  reportdefinition?: { measures: string[] }
  reportname?: string
  dateexecuted?: Maybe<string>
}

type Props = {
  reportData: ReportData
}

export const ReportCard = ({ reportData }: Props) => {
  const { t } = useTranslation(['reports'])
  const location = useLocation()
  const generatePath = useAbsolutePath()
  const { formatRelativeDate } = useLocalizedDate()

  if (!reportData) return

  const {
    jobname,
    reporttype,
    reportid,
    reportname,
    reportdescription,
    jobstate,
    jobid,
    reportdefinition,
    dateexecuted,
  } = reportData

  const getReportUrl = () => {
    const path = generatePath(RootRoute.Report, { id: reportid })
    const searchParams = createSearchParams({
      backTo: location.pathname,
      ...(jobid && { reportJobId: String(jobid) }),
    })

    return `${path}?${searchParams}`
  }

  const description =
    reportdescription ||
    t(`reports:report_keys:${reportdefinition?.measures.at(0)}`, {
      defaultValue: '',
    })

  return (
    <Grid size={{ xs: 12, md: 4, lg: 3 }}>
      <Card elevation={0}>
        <CardActionArea LinkComponent={Link} to={getReportUrl()}>
          <Stack
            sx={{
              display: 'grid',
              gridTemplateAreas: '"cardText cardIcon"',
              gridTemplateColumns: '1fr auto',
              alignItems: 'center',
              p: 2,
              columnGap: 1,
            }}
          >
            <List
              sx={{
                gridArea: 'cardText',
                overflow: 'hidden',
              }}
            >
              <ListItem
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: 0,
                  p: 0,
                }}
              >
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <Chip
                    label={t(`reports:report_types.${reporttype}`)}
                    color="gray"
                    size="small"
                    sx={{ width: 'max-content', mb: 2 }}
                  />

                  {jobstate &&
                    [
                      ReportJobState.InProgress,
                      ReportJobState.Pending,
                    ].includes(jobstate) && (
                      <Chip
                        label={t(`reports:report_states.${jobstate}`)}
                        color={
                          jobstate === ReportJobState.InProgress
                            ? 'blue'
                            : 'grayLight'
                        }
                        size="small"
                        sx={{ width: 'max-content', mb: 2 }}
                      />
                    )}
                </Box>

                <ListItemText
                  primary={reportname || jobname}
                  secondary={
                    dateexecuted
                      ? formatRelativeDate(dateexecuted)
                      : description
                  }
                  slotProps={{
                    primary: { variant: 'body02', color: 'text.primary' },
                    secondary: { variant: 'body01' },
                  }}
                  sx={{
                    textWrap: 'nowrap',
                    textOverflow: 'ellipsis',
                    maxWidth: '95%',
                    '.MuiListItemText-secondary': {
                      color: 'gray70',
                    },
                  }}
                />
              </ListItem>
            </List>
            <ChevronRightIcon sx={{ gridArea: 'cardIcon' }} />
          </Stack>
        </CardActionArea>
      </Card>
    </Grid>
  )
}
