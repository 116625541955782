import {
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  SxProps,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import {
  generatePath,
  matchPath,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import { useMe } from '../../hooks/me'
import { useSiteSelector } from '../../hooks/site-selector'
import { useTracking } from '../../hooks/tracking'
import { RootRoute } from '../../pages'

type Props = {
  sx?: SxProps
  isSidebar?: boolean
}

export const SiteSelector = (props: Props) => {
  const { me } = useMe()
  const [open, setOpen] = useState(false)
  const { trackButtonClick } = useTracking()
  const location = useLocation()
  const navigate = useNavigate()

  const { switchSite } = useSiteSelector()

  const navigateToSite = (siteId: number) => {
    const currentRoute =
      Object.values(RootRoute).find((r) => matchPath(r, location.pathname)) ||
      RootRoute.Home

    const pathParams = matchPath(currentRoute, location.pathname)?.params || {}

    void navigate(generatePath(currentRoute, { ...pathParams, siteId }))
  }

  const onChange = (event: SelectChangeEvent<number>) => {
    const siteId = Number(event.target.value)

    trackButtonClick({
      name: 'site-selector-switch-site',
      destinationSiteId: siteId,
    })

    void switchSite(siteId).then(() => navigateToSite(siteId))
  }

  if (!me) {
    return null
  }

  return (
    <Box sx={props.sx}>
      {me.sites.length === 1 ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            py: 0.5,
          }}
        >
          <Typography variant="body02">
            {me?.sites[0]?.eshopname || me?.sites[0]?.eshopid}
          </Typography>
        </Box>
      ) : (
        <Select
          value={me.siteId}
          onChange={onChange}
          inputProps={{
            ['data-testid']: 'switch-site-selector-input',
          }}
          sx={{
            height: '100%',
            '.MuiInput-input:focus': {
              background: 'inherit',
            },
            '&::before, &:not(.Mui-disabled):hover::before': {
              borderBottomWidth: '1px',
            },
            '&::after': {
              border: 0,
            },
            ...(props.isSidebar
              ? {
                  background: 'inherit',
                  display: 'flex',
                  justifyContent: 'center',
                  color: (t) => t.palette.white,
                  '.MuiSelect-icon': {
                    color: (t) => t.palette.white,
                    position: 'relative',
                  },
                  '.MuiInput-input.MuiSelect-select': {
                    width: 'auto',
                    padding: (t) => t.spacing(1, 1, 1, 0),
                  },

                  '&::before, &:not(.Mui-disabled):hover::before': {
                    borderColor: (t) => t.palette.blue70,
                    borderBottomWidth: '2px',
                  },
                }
              : {}),
          }}
          data-testid="switch-site-selector"
          open={open}
          onOpen={() => setOpen(true)}
          onClick={(evt) => {
            if (evt.currentTarget.classList.contains('MuiBackdrop-root')) {
              setOpen(false)
            }
          }}
          onClose={() => setOpen(false)}
        >
          {me.sites.map((site) => (
            <MenuItem
              value={site.eshopid}
              key={site.eshopid}
              data-testid={`switch-site-button-${site.eshopid}`}
            >
              <Typography variant="body02">
                {site.eshopname || site.eshopid}
              </Typography>
            </MenuItem>
          ))}
        </Select>
      )}
    </Box>
  )
}
