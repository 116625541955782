import { Box, Button, Paper, Stack, Typography } from '@mui/material'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import {
  CashRegisterFragment,
  Report,
  ReportJobsQuery,
  WarehouseFragment,
} from '../../generated/graphql'
import { ArrayElement } from '../../utils/types'
import { FilterChips } from './filter-chips'
import { ReportJobFilter } from './report-job-filter'
import { ReportsContext, ReportsContextType } from './reports-context'

type Props = {
  report?: Report
  reportJob?: ArrayElement<ReportJobsQuery['reportJobs']['items']>
  reportJobExists: boolean
  allCashRegisters: CashRegisterFragment[]
  allWarehouses: WarehouseFragment[]
}

export const NavigationSection = (props: Props) => {
  const {
    report,
    reportJob,
    reportJobExists,
    allCashRegisters,
    allWarehouses,
  } = props
  const { t } = useTranslation(['reports'])

  const { onAddReportJob, isAddingReportJob } = useContext(
    ReportsContext,
  ) as ReportsContextType

  const handleAddReportJob = async () => {
    if (report) {
      await onAddReportJob(report)
    }
  }

  const filters = reportJob?.reportdefinition?.filters
  return (
    <Box component={Paper} elevation={0} sx={{ p: 2 }}>
      <Stack
        direction="row"
        columnGap={2}
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 0,
          pb: 0,
        }}
      >
        <Stack>
          {!reportJobExists ? (
            <Typography variant="body02" fontSize="16pt" fontWeight={700}>
              {t('reports:no_report_generated')}
            </Typography>
          ) : (
            <ReportJobFilter />
          )}
          {filters && (
            <FilterChips
              filters={filters}
              allCashRegisters={allCashRegisters}
              allWarehouses={allWarehouses}
            />
          )}
        </Stack>

        {reportJobExists && (
          <Button
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={handleAddReportJob}
            disabled={isAddingReportJob}
            type="button"
            loading={isAddingReportJob}
          >
            {t('reports:refresh_data')}
          </Button>
        )}
      </Stack>
    </Box>
  )
}
